export class NameInDatabase {
    static TRUE = new NameInDatabase("TRUE");
    static FALSE = new NameInDatabase("FALSE");
    static NO_DATABASE = new NameInDatabase("NO_DATABASE");
    static NO_STORE_NAME = new NameInDatabase("NO_STORE_NAME");

    constructor(name) {
        this.name = name;
    }
}

export class NumberInDatabase {
    static TRUE = new NumberInDatabase("TRUE");
    static NUMBER_DOES_NOT_MATCH = new NumberInDatabase("NUMBER_DOES_NOT_MATCH");
    static NAME_NOT_IN_DATABASE = new NumberInDatabase("NAME_NOT_IN_DATABASE");
    static NO_NUMBER = new NumberInDatabase("NO_NUMBER");
    static NO_NUMBERS_IN_DATABASE = new NumberInDatabase("NO_NUMBERS_IN_DATABASE");
    static NO_DATABASE = new NumberInDatabase("NO_DATABASE");
    static NO_STORE_NAME = new NumberInDatabase("NO_STORE_NAME");

    constructor(name) {
        this.name = name;
    }
}

export class PriceInDatabase {
    static TRUE = new PriceInDatabase("TRUE");
    static PRICE_DOES_NOT_MATCH = new PriceInDatabase("PRICE_DOES_NOT_MATCH");
    static NAME_NOT_IN_DATABASE = new PriceInDatabase("NAME_NOT_IN_DATABASE");
    static NO_DATABASE = new PriceInDatabase("NO_DATABASE");
    static NO_STORE_NAME = new PriceInDatabase("NO_STORE_NAME");
    static NO_DATE = new PriceInDatabase("NO_DATE");
    static NO_PRICE = new PriceInDatabase("NO_PRICE");

    constructor(name) {
        this.name = name;
    }
}