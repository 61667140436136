import React from 'react';
import {withTranslation} from "react-i18next";
import {Divider} from "primereact/divider";
import {Button} from "primereact/button";

class LegalPageBottom extends React.Component {
    render() {
        const t = this.props.t;
        return (
            <>
                <Divider/>
                <div className="flex justify-content-center align-items-center gap-2">
                    <Button label={t("common.imprint")} className="p-button-secondary p-button-text" onClick={() => window.open(t("common.imprintLink"))}/>
                    <Button label={t("common.privacyPolicy")} className="p-button-secondary p-button-text" onClick={() => window.open(t("common.privacyPolicyLink"))}/>
                </div>
                <div style={{height: this.props.bottomOffset}}/>
            </>
        );
    }
}

export default withTranslation()(LegalPageBottom);