import React from 'react';
import ImageUpload from "./ImageUpload";
import {Button} from "primereact/button";
import DatabaseUpload from "./DatabaseUpload";

import {withTranslation} from "react-i18next";
import {BlockUI} from "primereact/blockui";
import {ConfirmDialog} from "primereact/confirmdialog";
import {ProgressSpinner} from "primereact/progressspinner";
import LegalPageBottom from "../LegalPageBottom";
import {InputSwitch} from "primereact/inputswitch";

class Startpage extends React.Component {
    render() {
        const t = this.props.t;
        return (
            <>
                <div className="flex flex-column align-items-center justify-content-center gap-2"
                     style={{height: "calc(100vh - 5rem)"}}>
                    <ImageUpload imageFile={this.props.imageFile} imageUrl={this.props.imageUrl} onImageChange={(image) => this.props.onImageChange(image)}/>
                    <div className="flex align-items-center gap-3 mt-2">
                        <div className="flex align-items-center">
                            <InputSwitch checked={this.props.dataUsage} onChange={(e) => this.props.onDataUsageChange(e.value)}/>
                        </div>
                        <div className={"max-w-15rem cursor-pointer text-justify " + (this.props.dataUsage ? "text-primary" : "text-color-secondary")} onClick={() => this.props.onDataUsageChange(!this.props.dataUsage)}>
                            {t("start.dataUsageExplanation")}
                        </div>
                    </div>
                </div>
                {/*<div className="p-2 flex flex-column align-items-center justify-content-center gap-2">*/}
                {/*    <ImageUpload imageFile={this.props.imageFile} imageUrl={this.props.imageUrl} onImageChange={(image) => this.props.onImageChange(image)}/>*/}
                {/*    <div className="p-2"/>*/}
                {/*    <DatabaseUpload databaseFile={this.props.databaseFile} onDatabaseChange={(database) => this.props.onDatabaseChange(database)}*/}
                {/*                    validDatabaseFile={this.props.validDatabaseFile} loadDatabaseFile={this.props.loadDatabaseFile}*/}
                {/*    />*/}
                {/*    <div className="p-2"/>*/}
                {/*    <Button label={t("start.transcribe")} icon="pi pi-chevron-right" iconPos="right" disabled={!this.isReady()} onClick={() => this.props.onTranscribeClick()}/>*/}
                {/*</div>*/}
                {this.renderBottomButton()}
            </>
        );
    }

    renderBottomButton() {
        const t = this.props.t;
        return (
            <>
                <div className="fixed block min-w-full bottom-0 z-5 pointer-events-none">
                    <div className="flex justify-content-end">
                        <Button label={t("start.transcribe")} icon="pi pi-chevron-right" iconPos="right"
                                className="p-button-rounded m-3 shadow-4 pointer-events-auto"
                                disabled={this.props.imageFile === null} onClick={() => this.props.onTranscribeClick()}
                        />
                    </div>
                </div>
                <LegalPageBottom bottomOffset="5rem"/>
            </>
        );
    }
}

export default withTranslation()(Startpage);