import React from 'react';
import {Calendar} from "primereact/calendar";
import FilterOption from "./FilterOption";
import {Dropdown} from "primereact/dropdown";
import {MultiSelect} from "primereact/multiselect";
import TimeGroupingType from "./TimeGroupingType";

import {withTranslation} from "react-i18next";

class AggregateFilter extends React.Component {

    timeGroupingTemplate(item) {
        console.log("Make template for");
        console.log(item);
        const t = this.props.t;
        switch (item) {
            case TimeGroupingType.MONTH:
                return t("statistics.month");
            case TimeGroupingType.WEEK:
                return t("statistics.week");
            case TimeGroupingType.DAY:
                return t("statistics.day");
            default:
                return item.name;
        }
    }

    setFilter(filterName, setting, value) {
        let newFilter = {...this.props.filter};
        let newFilterName = {...newFilter[filterName]};
        newFilterName[setting] = value;
        newFilter[filterName] = newFilterName;
        this.props.onChange(newFilter);
    }

    render() {
        const t = this.props.t;
        return (
            <div className="flex flex-column m-2">
                <FilterOption name={t("statistics.timePeriod")} enabled={this.props.filter["timeRange"]["activated"]}
                              onChange={(activated) => this.setFilter("timeRange", "activated", activated)}>
                    <Calendar dateFormat="dd.mm.yy" selectionMode="range" showIcon
                              style={{width: "100%"}} disabled={!this.props.filter["timeRange"]["activated"]}
                              value={this.props.filter["timeRange"]["value"]}
                              onChange={e => this.setFilter("timeRange", "value", e.value)}
                              touchUI
                    />
                </FilterOption>
                <FilterOption name={t("statistics.timeGrouping")} enabled={this.props.filter["groupTime"]["activated"]}
                              onChange={(activated) => this.setFilter("groupTime", "activated", activated)}>
                    <Dropdown options={[TimeGroupingType.MONTH, TimeGroupingType.WEEK, TimeGroupingType.DAY]} optionLabel={"name"}
                              itemTemplate={(item) => this.timeGroupingTemplate(item)}
                              valueTemplate={(item) => this.timeGroupingTemplate(item)} style={{width: "100%"}}
                              disabled={!this.props.filter["groupTime"]["activated"]}
                              value={this.props.filter["groupTime"]["value"]}
                              onChange={e => this.setFilter("groupTime", "value", e.value)}/>
                </FilterOption>
                <FilterOption name={t("statistics.stores")} enabled={this.props.filter["stores"]["activated"]}
                              onChange={(activated) => this.setFilter("stores", "activated", activated)}>
                    <MultiSelect options={this.props.allStores} style={{width: "100%"}}
                                 disabled={!this.props.filter["stores"]["activated"]}
                                 value={this.props.filter["stores"]["value"]}
                                 onChange={e => this.setFilter("stores", "value", e.value)} maxSelectedLabels={3}/>
                </FilterOption>
                <FilterOption name={t("statistics.categories")} enabled={this.props.filter["categories"]["activated"]}
                              onChange={(activated) => this.setFilter("categories", "activated", activated)}>
                    <MultiSelect options={this.props.allCategories} style={{width: "100%"}}
                                 disabled={!this.props.filter["categories"]["activated"]}
                                 value={this.props.filter["categories"]["value"]}
                                 onChange={e => this.setFilter("categories", "value", e.value)} maxSelectedLabels={3}/>
                </FilterOption>
            </div>
        );
    }
}

export default withTranslation()(AggregateFilter);