import React from 'react';
import '/node_modules/primeflex/primeflex.css';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import Header from "./Header";
import SidebarMenu from "./SidebarMenu";
import PageType from "./PageType";
import ReceiptView from "./receipt_page/ReceiptView";
import {
    addItem,
    convertToEditableReceipt, convertToReceipt,
    deleteItem,
    editItem,
    editReceipt,
    updateDatabase
} from "../data/ReceiptEditHelper";
import Startpage from "./start_page/Startpage";
import {claimNextId} from "../data/IdHelper";
import {addIds, mergeDatabase, mergeNewReceipt, mergeReceipts} from "../data/MergeHelper";
import {requestResult, requestStartOfTranscription} from "../data/RequestHelper";
import AggregateView from "./aggregate_view/AggregateView";
import {Toast} from "primereact/toast";
import {generateFeedback} from "../data/ValidationHelper";
import {getFieldFeedbacks, getItemFeedbacks} from "../data/FeedbackUtil";
import {readFile, readLocalStorage, savedReceiptsWithoutIds, writeLocalStorage} from "../data/Serialization";
import {
    addInputDataInProcess,
    addReceiptInProcess,
    changeReceiptInProcess,
    getReceiptInProcess, ReceiptProcessStatus,
    removeInputDataInProcess, removeReceiptInProcess
} from "../data/ProcessHelper";

import {withTranslation} from "react-i18next";
import {file} from "./base";
import DataAdministrationPage from "./data_administration_page/DataAdministrationPage";
import LegalPageBottom from "./LegalPageBottom";

class App extends React.Component {
    constructor(props) {
        super(props);

        this.toast = null;

        this.newStepsToOldSteps = {
            INITIALISING: "input",
            CONVERTING_IMAGE: "split",
            BINARIZING_IMAGE: "convert",
            SEGMENTING: "binarize",
            RECOGNIZING_LINES: "segmentation",
            OCR: "pipelinetestblock",
            INTERPRETING_TEXTS: "ocr",
            CONCATENATING_TEXT: "interpret",
            FINISHED: "concatenate"
        }

        this.state = {
            // data related states
            savedReceipts: [],
            savedDatabase: [],

            receiptInEdit: null,
            receiptFeedback: null,
            receiptsInProcess: [],

            images: {}, // ein Dictionary, dass zu manchen Kassenzetteln Bilder bereit hält
            debug: {}, // ein Dictionary, dass zu manchen Kassenzetteln Debug-Daten bereit hält

            inputDataInProcess: [],

            // view related states
            menuOpened: false,

            openedPage: {
                type: PageType.START
            },

            imageFile: null,
            imageUrl: null,
            dataUsage: false,

            activeTabIndices: null,

            importFile: null,
            usedImportFile: false,
            validImportFile: false,
            loadImportFile: false

        }

        this.languages = {
            de: {nativeName: "Deutsch"},
            en: {nativeName: "English"}
        };
    }

    componentDidMount() {
        this.loadLocalStorage();
    }

    async loadLocalStorage() {
        let t = this.props.t;
        let readLocalStorageResult = readLocalStorage();
        if (readLocalStorageResult.success && readLocalStorageResult.foundData) {
            await this.asyncSetState({
                savedReceipts: mergeReceipts([], readLocalStorageResult.receipts),
                savedDatabase: mergeDatabase([], readLocalStorageResult.store_database)
            });
            t = this.props.t;
            this.showToast("success", t("start.toasts.loadLocalStorageSuccessfully.summary"), t("start.toasts.loadLocalStorageSuccessfully.detail"));
        } else if (!readLocalStorageResult.success && readLocalStorageResult.reason === "syntax") {
            this.showToast("error", t("start.toasts.loadLocalStorageFailedSyntax.summary"), t("start.toasts.loadLocalStorageFailedSyntax.detail"));
        } else if (!readLocalStorageResult.success && readLocalStorageResult.reason === "schema") {
            this.showToast("error", t("start.toasts.loadLocalStorageFailedSchema.summary"), t("start.toasts.loadLocalStorageFailedSchema.detail"));
        } else if (!readLocalStorageResult.success && readLocalStorageResult.reason === "security") {
            this.showToast("error", t("start.toasts.loadLocalStorageFailedSecurity.summary"), t("start.toasts.loadLocalStorageFailedSecurity.detail"));
        }
    }

    renderMainComponent() {
        if (this.state.openedPage.type === PageType.START) {
            return (
                <Startpage imageFile={this.state.imageFile} imageUrl={this.state.imageUrl} onImageChange={(image) => this.changePreviewImage(image)}
                           onTranscribeClick={() => this.newUploadImageAndInputData()} dataUsage={this.state.dataUsage}
                           onDataUsageChange={(newValue) => this.setState({dataUsage: newValue})}
                />
            );
        } else if (this.state.openedPage.type === PageType.RECEIPT) {
            return (
                <ReceiptView
                    onEditItem={(receiptId, itemId, field, newValue) => this.changeReceiptItem(receiptId, itemId, field, newValue)}
                    receiptId={this.state.openedPage.receiptId} receiptInEdit={this.state.receiptInEdit}
                    receiptsInProcess={this.state.receiptsInProcess} imageUrl={this.getImageUrl()}
                    onReceiptChange={(receiptId, storeName, date, sum) => this.changeReceipt(receiptId, storeName, date, sum)}
                    onAddItem={(receiptId, beforeIndex) => this.addReceiptItem(receiptId, beforeIndex)}
                    onDeleteItem={(receiptId, itemId) => this.deleteReceiptItem(receiptId, itemId)}
                    onSave={(receiptId) => this.saveReceipt(receiptId)}
                    onDeleteReceipt={(receiptId) => this.deleteReceipt(receiptId)}
                    receiptFeedback={this.state.receiptFeedback} debug={this.getDebug()}
                />
            );
        } else if (this.state.openedPage.type === PageType.STATISTIC) {
            return (
                <AggregateView savedReceipts={this.state.savedReceipts}/>
            );
        } else if (this.state.openedPage.type === PageType.DATA_ADMINISTRATION) {
            return (
                <DataAdministrationPage importFile={this.state.importFile} validImport={this.state.validImportFile}
                                        loadImport={this.state.loadImportFile} usedImport={this.state.usedImportFile}
                                        onImportFileChange={(newImportFile) => this.changeImportFile(newImportFile)}
                                        onExport={() => this.download()} onImport={() => this.importFile()}
                />
            );
        }
        return (<></>);
    }

    render() {
        return (
            <div>
                <Header onMenuOpen={() => this.setMenuOpened(true)} languages={Object.keys(this.languages)}
                        openedPage={this.state.openedPage}/>
                <SidebarMenu visible={this.state.menuOpened} onHide={() => this.setMenuOpened(false)}
                             onClickPage={(page) => this.openPage(page)} activeIndices={this.state.activeTabIndices}
                             onActiveIndicesChange={(indices) => this.changeTab(indices)}
                             savedReceipts={this.state.savedReceipts} receiptsInProcess={this.state.receiptsInProcess}
                             openedPage={this.state.openedPage}
                             onReceiptSelect={(receiptId) => this.selectReceipt(receiptId)}
                />
                {this.renderMainComponent()}
                <Toast ref={(el) => this.toast = el} style={{width: "min(calc(100% - 40px), 25rem)"}} className={"z-5"}/>
            </div>
        );
    }

    setMenuOpened(opened) {
        this.setState({menuOpened: opened});
    }

    openPage(page) {
        this.setState({openedPage: page});
    }

    selectReceipt(receiptId) {
        if (receiptId === null) {
            return;
        }
        this.setState(prevState => {
            if (prevState.openedPage.type === PageType.RECEIPT && prevState.openedPage.receiptId === receiptId) {
                return {
                    menuOpened: false
                };
            }
            let receipt = prevState.savedReceipts.find((r) => r.id === receiptId);
            let receiptInEdit = null;
            let receiptFeedback = null;
            if (receipt !== undefined) {
                receiptInEdit = convertToEditableReceipt(prevState.savedDatabase, receipt);
                receiptFeedback = generateFeedback(prevState.savedDatabase, receiptInEdit, this.props.t);
            }
            return {
                receiptInEdit: receiptInEdit,
                receiptFeedback: receiptFeedback,
                openedPage: {
                    type: PageType.RECEIPT,
                    receiptId: receiptId
                },
                menuOpened: false
            };
        });
    }

    changeTab(indices) {
        this.setState(prevState => {
            let openedPage = prevState.openedPage;
            let menuOpened = prevState.menuOpened;
            if (indices.includes(0) && prevState.openedPage.type !== PageType.START) {
                openedPage = {
                    type: PageType.START
                };
                menuOpened = false;
            }
            if (indices.includes(2) && prevState.openedPage.type !== PageType.STATISTIC) {
                openedPage = {
                    type: PageType.STATISTIC
                };
                menuOpened = false;
            }
            if (indices.includes(3) && prevState.openedPage.type !== PageType.DATA_ADMINISTRATION) {
                openedPage = {
                    type: PageType.DATA_ADMINISTRATION
                };
                menuOpened = false;
            }
            return {
                openedPage: openedPage,
                activeTabIndices: indices.filter((ind) => ind === 1),
                menuOpened: menuOpened
            }
        });
    }

    download() {
        let downloadReceipts = savedReceiptsWithoutIds(this.state.savedReceipts);
        let downloadDatabase = this.state.savedDatabase;
        let downloadObject = {
            receipts: downloadReceipts,
            store_database: downloadDatabase
        };
        let blob = new Blob([JSON.stringify(downloadObject, null, 2)], {type: "application/json"})
        let fileUrl = URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = fileUrl;
        a.setAttribute("download", "receipts.json");
        a.click();
        URL.revokeObjectURL(fileUrl);
    }

    async deleteReceipt(receiptId) {
        let successfullyDeleted = false;
        await this.asyncSetState(prevState => {
            if (prevState.receiptInEdit === null || prevState.receiptInEdit.id !== receiptId) {
                return {};
            }
            successfullyDeleted = true;
            let newSavedReceipts = prevState.savedReceipts.filter(r => r.id !== receiptId)
            let newActiveTabIndices = prevState.activeTabIndices;
            let index = newActiveTabIndices.indexOf(1);
            if (index !== -1 && newSavedReceipts.length === 0) {
                newActiveTabIndices = newActiveTabIndices.slice();
                newActiveTabIndices.splice(index, 1);
            }
            return {
                receiptInEdit: null,
                openedPage: {
                    type: PageType.START
                },
                activeTabIndices: newActiveTabIndices,
                savedReceipts: newSavedReceipts
            }
        });
        const t = this.props.t;
        if (successfullyDeleted) {
            this.showToast("success", t("receipts.toasts.deletedReceiptSuccessfully.summary"), t("receipts.toasts.deletedReceiptSuccessfully.detail"));
            this.writeToLocalStorage();
        } else {
            this.showToast("success", t("receipts.toasts.deleteReceiptFailed.summary"), t("receipts.toasts.deleteReceiptFailed.detail"));
        }
    }

    async saveReceipt(receiptId) {
        let t = this.props.t;
        let feedbackErrors = this.getFeedbackErrors();
        if (feedbackErrors.length > 0) {
            this.showToast("error", t("receipts.toasts.saveReceiptFailed.summary"), t("receipts.toasts.saveReceiptFailed.detail", {count: feedbackErrors.length}));
            return;
        }
        await this.asyncSetState(prevState => {
            if (prevState.receiptInEdit === null || prevState.receiptInEdit.id !== receiptId) {
                return {};
            }
            let receipt = convertToReceipt(prevState.receiptInEdit);
            let newReceipts = prevState.savedReceipts.slice();
            let index = newReceipts.findIndex((r) => r.id === receipt.id);
            newReceipts[index] = receipt;
            return {
                savedReceipts: newReceipts,
                savedDatabase: updateDatabase(prevState.savedDatabase, receipt)
            }
        });
        t = this.props.t;
        this.showToast("success", t("receipts.toasts.savedReceiptSuccessfully.summary"), t("receipts.toasts.savedReceiptSuccessfully.detail"));
        this.writeToLocalStorage();
    }


    getFeedbackErrors() {
        let errors = [];
        let receiptFieldFeedbacks = getFieldFeedbacks(this.state.receiptFeedback);
        receiptFieldFeedbacks = receiptFieldFeedbacks !== null ? receiptFieldFeedbacks : [];
        errors = errors.concat(receiptFieldFeedbacks.filter(ff => ff.severity === "error"));
        let itemFeedbacks = getItemFeedbacks(this.state.receiptFeedback);
        itemFeedbacks = itemFeedbacks !== null ? itemFeedbacks : [];
        for (let i = 0; i < itemFeedbacks.length; i++) {
            let itemFeedback = itemFeedbacks[i];
            let itemFieldFeedbacks = getFieldFeedbacks(itemFeedback);
            itemFieldFeedbacks = itemFieldFeedbacks !== null ? itemFieldFeedbacks : [];
            errors = errors.concat(itemFieldFeedbacks.filter(ff => ff.severity === "error"));
        }
        return errors;
    }

    addReceiptItem(receiptId, beforeIndex) {
        this.setState(prevState => {
            if (prevState.receiptInEdit === null || prevState.receiptInEdit.id !== receiptId) {
                return {};
            }
            let receiptInEdit = addItem(prevState.savedDatabase, prevState.receiptInEdit, beforeIndex);
            return {
                receiptInEdit: receiptInEdit,
                receiptFeedback: generateFeedback(prevState.savedDatabase, receiptInEdit, this.props.t)
            }
        });
    }

    deleteReceiptItem(receiptId, itemId) {
        this.setState(prevState => {
            if (prevState.receiptInEdit === null || prevState.receiptInEdit.id !== receiptId) {
                return {};
            }
            let receiptInEdit = deleteItem(prevState.receiptInEdit, itemId);
            return {
                receiptInEdit: receiptInEdit,
                receiptFeedback: generateFeedback(prevState.savedDatabase, receiptInEdit, this.props.t)
            }
        });
    }

    changeReceiptItem(receiptId, itemId, field, newValue) {
        this.setState(prevState => {
            if (prevState.receiptInEdit === null || prevState.receiptInEdit.id !== receiptId) {
                return {};
            }
            let receiptInEdit = editItem(prevState.savedDatabase, prevState.receiptInEdit, itemId, field, newValue);
            return {
                receiptInEdit: receiptInEdit,
                receiptFeedback: generateFeedback(prevState.savedDatabase, receiptInEdit, this.props.t)
            }
        });
    }

    changeReceipt(receiptId, storeName, date, sum) {
        this.setState(prevState => {
            if (prevState.receiptInEdit === null || prevState.receiptInEdit.id !== receiptId) {
                return {};
            }
            let receiptInEdit = editReceipt(prevState.savedDatabase, prevState.receiptInEdit, storeName, date, sum);
            return {
                receiptInEdit: receiptInEdit,
                receiptFeedback: generateFeedback(prevState.savedDatabase, receiptInEdit, this.props.t)
            }
        });
    }

    getImageUrl() {
        if (this.state.openedPage.type !== PageType.RECEIPT) {
            return null;
        }
        if (!(this.state.openedPage.receiptId in this.state.images)) {
            return null;
        }
        return this.state.images[this.state.openedPage.receiptId];
    }

    getDebug() {
        if (this.state.openedPage.type !== PageType.RECEIPT) {
            return null;
        }
        if (!(this.state.openedPage.receiptId in this.state.debug)) {
            return null;
        }
        return this.state.debug[this.state.openedPage.receiptId];
    }

    changePreviewImage(imageFile) {
        let oldUrl = null;
        if (imageFile === null) {
            this.setState(prevState => {
                oldUrl = prevState.imageUrl;
                return {
                    imageFile: null,
                    imageUrl: null
                }
            }, () => {
                if (oldUrl !== null) {
                    URL.revokeObjectURL(oldUrl);
                }
            });
            return;
        }
        let imageUrl = URL.createObjectURL(imageFile);
        this.setState(prevState => {
            oldUrl = prevState.imageUrl;
            return {
                imageFile: imageFile,
                imageUrl: imageUrl
            };
        }, () => {
            if (oldUrl !== null) {
                URL.revokeObjectURL(oldUrl);
            }
        });
    }

    async changeImportFile(importFile) {
        this.setState({
            importFile: importFile,
            loadImportFile: true,
            usedImportFile: true
        });
        if (importFile !== null && importFile.name.toLowerCase().endsWith(".json")) {
            if (importFile.name.toLowerCase().endsWith(".json")) {
                let loadImportFileResult = await readFile(importFile);
                await this.asyncSetState(prevState => {
                    if (prevState.importFile === importFile) {
                        return {
                            validImportFile: loadImportFileResult.success,
                            loadImportFile: false
                        }
                    }
                });
                if (!loadImportFileResult.success) {
                    const t = this.props.t;
                    if (loadImportFileResult.reason === "io") {
                        this.showToast(
                            "error",
                            t("dataAdministration.toasts.summaries.invalidImportFile"),
                            t("dataAdministration.toasts.details.invalidImportFileIo")
                        );
                    } else if (loadImportFileResult.reason === "syntax") {
                        this.showToast(
                            "error",
                            t("dataAdministration.toasts.summaries.invalidImportFile"),
                            t("dataAdministration.toasts.details.invalidImportFileSyntax")
                        );
                    } else if (loadImportFileResult.reason === "schema") {
                        this.showToast(
                            "error",
                            t("dataAdministration.toasts.summaries.invalidImportFile"),
                            t("dataAdministration.toasts.details.invalidImportFileSchema")
                        );
                    }
                }
            }
        } else {
            await this.asyncSetState(prevState => {
                if (prevState.importFile === importFile) {
                    return {
                        validImportFile: false,
                        loadImportFile: false
                    }
                }
            });
            if (importFile !== null) {
                const t = this.props.t;
                this.showToast(
                    "error",
                    t("dataAdministration.toasts.summaries.invalidImportFile"),
                    t("dataAdministration.toasts.details.invalidImportFileType")
                );
            }
        }
    }

    async newUploadImageAndInputData() {
        let imageFile = this.state.imageFile;
        let imageUrl = this.state.imageUrl;
        let dataUsageAllowed = this.state.dataUsage;

        this.resetStartForm();
        await this.loadImage(imageFile, imageUrl, dataUsageAllowed);
    }

    async importFile() {
        let importFile = this.state.importFile;
        this.setState({
            importFile: null,
            validImportFile: false,
            loadImportFile: false,
            usedImportFile: false
        });
        await this.loadImportFile(importFile);
    }

    async loadImportFile(importFile) {
        let inputDataInProcess = claimNextId();
        this.setState(prevState => {
            return {
                inputDataInProcess: addInputDataInProcess(prevState.inputDataInProcess, inputDataInProcess)
            };
        })
        let loadInputDataResult = await readFile(importFile);
        if (loadInputDataResult.success && loadInputDataResult.foundData) {
            await this.asyncSetState(prevState => {
                return {
                    inputDataInProcess: removeInputDataInProcess(prevState.inputDataInProcess, inputDataInProcess),
                    savedReceipts: mergeReceipts(prevState.savedReceipts, loadInputDataResult.receipts),
                    savedDatabase: mergeDatabase(prevState.savedDatabase, loadInputDataResult.store_database)
                };
            });
            const t = this.props.t;
            this.showToast(
                "success",
                t("dataAdministration.toasts.summaries.importedDataSuccessfully"),
                t("dataAdministration.toasts.details.importedDataSuccessfully", {file: importFile.name})
            );
            this.writeToLocalStorage();
        } else if (!loadInputDataResult.success) {
            await this.asyncSetState(prevState => {
                return {
                    inputDataInProcess: removeInputDataInProcess(prevState.inputDataInProcess, inputDataInProcess)
                };
            });
        }
    }

    async loadImage(imageFile, imageUrl, dataUsageAllowed) {
        let t = this.props.t;
        let newReceiptId = claimNextId();

        this.setState(prevState => {
            let imageDict = Object.assign({}, prevState.images);
            imageDict[newReceiptId] = imageUrl;
            return {
                images: imageDict,
                receiptsInProcess: addReceiptInProcess(prevState.receiptsInProcess, {
                    status: ReceiptProcessStatus.CREATE_PROJECT,
                    receiptId: newReceiptId
                }),
                openedPage: {
                    type: PageType.RECEIPT,
                    receiptId: newReceiptId
                }
            };
        });

        let startResponse;
        try {
            startResponse = await requestStartOfTranscription(imageFile, {store_databases: this.state.savedDatabase}, dataUsageAllowed);
        } catch (error) {
            console.log(error);
            await this.asyncSetState(prevState => {
                return {
                    receiptsInProcess: removeReceiptInProcess(prevState.receiptsInProcess, newReceiptId),
                    openedPage: {
                        type: PageType.START
                    }
                };
            });
            t = this.props.t;
            this.showToast("error", t("receipts.toasts.startTranscriptionFailedNetwork.summary"), t("receipts.toasts.startTranscriptionFailedNetwork.detail"));
            return;
        }
        if (startResponse.project_id === -1) {
            await this.asyncSetState(prevState => {
                return {
                    receiptsInProcess: removeReceiptInProcess(prevState.receiptsInProcess, newReceiptId),
                    openedPage: {
                        type: PageType.START
                    }
                };
            });
            t = this.props.t;
            this.showToast("error", t("receipts.toasts.startTranscriptionFailedBackend.summary"), t("receipts.toasts.startTranscriptionFailedBackend.detail"));
            return;
        }
        t = this.props.t;
        this.showToast("success", t("receipts.toasts.startTranscriptionSuccessfully.summary"), t("receipts.toasts.startTranscriptionSuccessfully.detail"))
        let projectId = startResponse.project_id;

        let finalResponse;
        try {
            finalResponse = await this.finishProcess(newReceiptId, projectId);
        } catch (error) {
            console.log(error);
            t = this.props.t;
            this.showToast("error", t("receipts.toasts.transcriptionFailedNetwork.summary"), t("receipts.toasts.transcriptionFailedNetwork.detail"));
            return;
        }
        t = this.props.t;
        this.showToast("success", t("receipts.toasts.finishedTranscriptionSuccessfully.summary"), t("receipts.toasts.finishedTranscriptionSuccessfully.detail"));
        await this.loadDebugInformation(newReceiptId, finalResponse);
    }

    finishProcess(newReceiptId, projectId) {
        return new Promise((resolve, reject) => {
            let intervalId = setInterval(() => this.checkProcess(newReceiptId, projectId, resolve, reject), 1000);
            this.setState(prevState => {
                return {
                    receiptsInProcess: changeReceiptInProcess(prevState.receiptsInProcess, {
                        status: ReceiptProcessStatus.WAIT_FOR_RESULT,
                        receiptId: newReceiptId,
                        projectId: projectId,
                        intervalId: intervalId,
                        latestFinishedStep: null
                    })
                }
            });
        });
    }

    async checkProcess(newReceiptId, projectId, resolve, reject) {
        let resultResponse;
        let error = null;
        try {
            resultResponse = await requestResult(projectId);
        } catch (caughtError) {
            error = caughtError;
        }
        if (resultResponse.status === "not_existing") {
            error = new Error("The requested receipt does not exist on the server.");
        }
        if (error !== null) {
            let removed = false;
            await this.asyncSetState(prevState => {
                let receiptInProcess = getReceiptInProcess(prevState.receiptsInProcess, newReceiptId);
                if (receiptInProcess !== null) {
                    removed = true;
                    let newOpenedPage = prevState.openedPage;
                    if (prevState.openedPage.type === PageType.RECEIPT && prevState.openedPage.receiptId === newReceiptId) {
                        newOpenedPage = {
                            type: PageType.START
                        }
                    }
                    return {
                        openedPage: newOpenedPage,
                        receiptInProcess: removeReceiptInProcess(prevState.receiptsInProcess, newReceiptId)
                    };
                }
                return {};
            });
            if (removed) {
                reject(error);
            }
            return;
        }
        if (resultResponse.status === "pending") {
            this.setState(prevState => {
                let receiptInProcess = getReceiptInProcess(prevState.receiptsInProcess, newReceiptId)
                if (receiptInProcess !== null) {
                    return {
                        receiptsInProcess: changeReceiptInProcess(prevState.receiptsInProcess, {
                            status: ReceiptProcessStatus.WAIT_FOR_RESULT,
                            receiptId: receiptInProcess.receiptId,
                            projectId: receiptInProcess.projectId,
                            intervalId: receiptInProcess.intervalId,
                            latestFinishedStep: resultResponse.latest_finished_step !== undefined ? resultResponse.latest_finished_step : this.convertNewStepsToOldSteps(resultResponse.step)
                        })
                    };
                }
                return {};
            });
        } else if (resultResponse.status === "completed") {
            let intervalId = null;
            await this.asyncSetState(prevState => {
                let receiptInProcess = getReceiptInProcess(prevState.receiptsInProcess, newReceiptId);
                if (receiptInProcess !== null) {
                    intervalId = receiptInProcess.intervalId;
                    let newReceipt = addIds(resultResponse.receipt_based_json, newReceiptId);
                    let newReceipts = prevState.savedReceipts.concat([newReceipt]);
                    let newDatabase = mergeDatabase(prevState.savedDatabase, resultResponse.item_storage.store_databases);
                    let newReceiptInEdit = prevState.receiptInEdit;
                    let newReceiptFeedback = prevState.receiptFeedback;
                    if (prevState.openedPage.type === PageType.RECEIPT && prevState.openedPage.receiptId === newReceiptId) {
                        newReceiptInEdit = convertToEditableReceipt(newDatabase, newReceipt);
                        newReceiptFeedback = generateFeedback(newDatabase, newReceiptInEdit, this.props.t);
                    }
                    return {
                        receiptsInProcess: removeReceiptInProcess(prevState.receiptsInProcess, newReceiptId),

                        savedReceipts: newReceipts,
                        savedDatabase: newDatabase,

                        receiptInEdit: newReceiptInEdit,
                        receiptFeedback: newReceiptFeedback
                    };
                }
                return {};
            });
            if (intervalId !== null) {
                clearInterval(intervalId);
                resolve(resultResponse);
            }
        }
    }

    async loadDebugInformation(newReceiptId, finalResponse) {
        let plainText = this.convertDebugXmlToPlainText(finalResponse.plain_text);
        if (plainText === "") {
            plainText = finalResponse.plain_text;
        }
        // await this.asyncSetState(prevState => {
        //     let plainTextsDict = Object.assign({}, prevState.plainTexts);
        //     plainTextsDict[newReceiptId] = plainText;
        //     return {
        //         plainTexts: plainTextsDict
        //     };
        // });
        // const baselinesResponse = await fetch(finalResponse.baselines_base64);
        // const blob = await baselinesResponse.blob();
        // const baselineUrl = URL.createObjectURL(blob);
        // await this.asyncSetState(prevState => {
        //     let baselinesDict = Object.assign({}, prevState.baselines);
        //     baselinesDict[newReceiptId] = baselineUrl;
        //     return {
        //         baselines: baselinesDict
        //     };
        // });
        const binarizedImageResponse = await fetch(finalResponse.binarized_image_base64);
        const recognizedTextAreasResponse = await fetch(finalResponse.recognized_text_areas_base64);
        const recognizedLineAreasResponse = await fetch(finalResponse.recognized_line_areas_base64);
        const lineTypesResponse = await fetch(finalResponse.line_types_base64);

        const newDebug = {
            binarizedImageUrl: URL.createObjectURL(await binarizedImageResponse.blob()),
            recognizedTextAreasUrl: URL.createObjectURL(await recognizedTextAreasResponse.blob()),
            recognizedLineAreasUrl: URL.createObjectURL(await recognizedLineAreasResponse.blob()),
            lineTypesUrl: URL.createObjectURL(await lineTypesResponse.blob()),
            plainTextUrl: URL.createObjectURL(new Blob([plainText], {type: "text/plain"}))
        };

        await this.asyncSetState(prevState => {
            let debugDict = Object.assign({}, prevState.debug);
            debugDict[newReceiptId] = newDebug;
            return {
                debug: debugDict
            }
        });
    }

    resetStartForm() {
        this.setState({
            imageFile: null,
            imageUrl: null
        });
    }

    writeToLocalStorage() {
        const t = this.props.t;
        let writeLocalStorageResult = writeLocalStorage(
            savedReceiptsWithoutIds(this.state.savedReceipts),
            this.state.savedDatabase
        );
        if (!writeLocalStorageResult.success) {
            this.showToast("error", t("common.toasts.writeLocalStorageFailed.summary"), t("common.toasts.writeLocalStorageFailed.detail"));
        }
    }

    showToast(severity, summary, detail) {
        this.toast.show({
            severity: severity,
            summary: summary,
            detail: detail,
            life: severity === "error" || severity === "warning" ? 10000 : 5000
        });
    }

    asyncSetState(newState) {
        return new Promise((resolve) => {
            this.setState(newState, () => resolve());
        });
    }

    convertDebugXmlToPlainText(debugXml) {
        let result = "";
        let textStartRegExp = /<Unicode>/gs;
        let textEndRegExp = /<\/Unicode>/gs;
        let remainingText = debugXml;

        while (remainingText.length > 0) {
            let startIndex = remainingText.search(textStartRegExp);
            if (startIndex === -1) {
                remainingText = "";
                break;
            }
            // assume that the startSplitted length is 2
            remainingText = remainingText.substring(startIndex + 9);
            let endIndex = remainingText.search(textEndRegExp);
            if (endIndex === -1) {
                throw new Error("Could not parse debugXml. Found an opening Unicode-tag but not a closing one.");
            }
            // assume that the endSplitted length is 2
            result = result + remainingText.substring(0, endIndex) + "\n";
            remainingText = remainingText.substring(endIndex + 10);
        }
        return result;
    }

    convertNewStepsToOldSteps(newStep) {
        if (newStep === null || newStep.toLowerCase() === newStep) {
            // it is actually an old step
            return newStep;
        }
        return this.newStepsToOldSteps[newStep];
    }
}

export default withTranslation()(App);