
import {validateItem} from "./ValidationHelper";
import {claimNextId} from "./IdHelper";
import {
    convertCurrencyStringToNumber,
    convertIntegerToString, convertNumberToCurrencyString, convertNumberToString,
    convertStringToInteger,
    convertStringToNumber
} from "./ConvertUtil";

/**
 * A lot of help functions that are used when the user edits a receipt.
 */

/**
 *
 * @param {StoreDatabase[]} database
 * @param {StringReceipt} receipt
 * @param {number} index
 * @returns {StringReceipt}
 */
export function addItem(database, receipt, index) {
    let newReceipt = Object.assign({}, receipt);
    let newItems = new Array(receipt.items.length + 1);
    let indexOffset = 0;
    for (let i = 0; i < newItems.length; i++) {
        if (i === index) {
            // add new
            newItems[i] = {
                id: claimNextId(),
                name: "",
                number: "",
                per_weight: "",
                amount: "",
                unit_price: "",
                weight: "",
                si_unit: "",
                price_per_si_unit: "",
                total_price: "",
                tax_category: "",
                category: "",
                validated: {}
            };
            newItems[i].validated = validateItem(database, receipt.store.name, receipt.date, newItems[i]);
            indexOffset = 1;
        } else {
            newItems[i] = receipt.items[i - indexOffset];
        }
    }
    newReceipt.items = newItems;
    return newReceipt;
}

/**
 *
 * @param {StringReceipt} receipt
 * @param {number} itemId
 * @returns {StringReceipt}
 */
export function deleteItem(receipt, itemId) {
    let newReceipt = Object.assign({}, receipt);
    newReceipt.items = receipt.items.filter((it) => it.id !== itemId);
    return newReceipt;
}

/**
 *
 * @param {StoreDatabase[]} database
 * @param {StringReceipt} receipt
 * @param {string} storeName
 * @param {Date|null} date
 * @param {string} sum
 * @returns {StringReceipt}
 */
export function editReceipt(database, receipt, storeName, date, sum) {
    let newReceipt = Object.assign({}, receipt);
    let newStore = Object.assign({}, receipt.store);
    newStore.name = storeName;
    newReceipt.store = newStore;
    newReceipt.date = date;
    newReceipt.sum = sum;
    let newItems = new Array(receipt.items.length);
    for (let i = 0; i < newItems.length; i++) {
        newItems[i] = Object.assign({}, receipt.items[i]);
        newItems[i].validated = validateItem(database, storeName, date, newItems[i]);
    }
    newReceipt.items = newItems;
    return newReceipt;
}

/**
 *
 * @param {StoreDatabase[]} database
 * @param {StringReceipt} receipt
 * @param {number} itemId
 * @param {string} field
 * @param {string} newValue
 * @returns {StringReceipt}
 */
export function editItem(database, receipt, itemId, field, newValue) {
    let newReceipt = Object.assign({}, receipt);
    let items = newReceipt.items;
    let newItems = new Array(items.length);
    for (let i = 0; i < items.length; i++) {
        if (items[i].id !== itemId) {
            newItems[i] = items[i];
        } else {
            newItems[i] = Object.assign({}, items[i]);
            newItems[i][field] = newValue;
            newItems[i].validated = validateItem(database, receipt.store.name, receipt.date, newItems[i]);
        }
    }
    newReceipt.items = newItems;
    return newReceipt;
}

/**
 *
 * @param {StoreDatabase[]} database
 * @param {Receipt} receipt
 * @returns {StringReceipt}
 */
export function convertToEditableReceipt(database, receipt) {
    let stringItems = new Array(receipt.items.length);
    for (let i = 0; i < receipt.items.length; i++) {
        let item = receipt.items[i];
        stringItems[i] = {
            id: item.id,
            name: item.name !== null ? "" + item.name : "",
            number: item.number !== null ? "" + convertIntegerToString(item.number) : "",
            per_weight: item.per_weight !== null ? "" + item.per_weight : "",
            amount: item.amount !== null ? "" + convertIntegerToString(item.amount) : "",
            unit_price: item.unit_price !== null ? "" + convertNumberToCurrencyString(item.unit_price) : "",
            weight: item.weight !== null ? "" + convertNumberToString(item.weight) : "",
            si_unit: item.si_unit !== null ? "" + item.si_unit : "",
            price_per_si_unit: item.price_per_si_unit !== null ? "" + convertNumberToCurrencyString(item.price_per_si_unit) : "",
            total_price: item.total_price !== null ? "" + convertNumberToCurrencyString(item.total_price) : "",
            tax_category: item.tax_category !== null ? "" + item.tax_category : "",
            category: item.category !== null ? "" + item.category : "",
            validated: {}
        };
        stringItems[i].validated = validateItem(database, receipt.store.name !== null ? receipt.store.name : "", receipt.date !== null ? new Date(receipt.date * 1000) : null, stringItems[i]);
    }
    let stringStore = {
        name: receipt.store.name !== null ? "" + receipt.store.name : "",
        address: {}
    }
    return {
        id: receipt.id,
        store: stringStore,
        items: stringItems,
        sum: receipt.sum !== null ? "" + convertNumberToCurrencyString(receipt.sum) : "",
        date: receipt.date !== null ? new Date(receipt.date * 1000) : null,
        tax_categories: {}
    }
}

/**
 *
 * @param {StringReceipt} editableReceipt
 * @returns {Receipt}
 */
export function convertToReceipt(editableReceipt) {
    let items = new Array(editableReceipt.items.length);
    for (let i = 0; i < items.length; i++) {
        let stringItem = editableReceipt.items[i];
        items[i] = {
            name: stringItem.name !== "" ? stringItem.name : null,
            number: stringItem.number !== "" ? convertStringToInteger(stringItem.number) : null,
            per_weight: stringItem.weight !== "",
            amount: stringItem.amount !== "" ? convertStringToInteger(stringItem.amount) : null,
            unit_price: stringItem.unit_price !== "" ? convertCurrencyStringToNumber(stringItem.unit_price) : null,
            weight: stringItem.weight !== "" ? convertStringToNumber(stringItem.weight) : null,
            si_unit: stringItem.si_unit !== "" ? stringItem.si_unit : null,
            price_per_si_unit: stringItem.price_per_si_unit !== "" ? convertCurrencyStringToNumber(stringItem.price_per_si_unit) : null,
            total_price: stringItem.total_price !== "" ? convertCurrencyStringToNumber(stringItem.total_price) : null,
            tax_category: stringItem.tax_category !== "" ? stringItem.tax_category : null,
            category: stringItem.category !== "" ? stringItem.category : null
        };
    }
    let store = {
        name: editableReceipt.store.name !== "" ? editableReceipt.store.name : null,
        address: null
    }
    return {
        id: editableReceipt.id,
        store: store,
        items: items,
        sum: editableReceipt.sum === "" ? null : convertCurrencyStringToNumber(editableReceipt.sum),
        date: editableReceipt.date === null ? null : editableReceipt.date.getTime() / 1000,
        tax_categories: null
    }
}

/**
 *
 * @param {StoreDatabase[]} database
 * @param {Receipt} receipt
 * @returns {StoreDatabase[]}
 */
export function updateDatabase(database, receipt) {
    let newDatabase = database.slice();
    let index = newDatabase.findIndex((sd) => sd.store === receipt.store.name);
    if (index === -1) {
        // create a new Database

        let newItems = [];
        for (let i = 0; i < receipt.items.length; i++) {
            let receiptItem = receipt.items[i];
            let newItem = {
                number: receiptItem.number,
                name: receiptItem.name,
                prices: [
                    {
                        date: receipt.date,
                        price: getPricePerOne(receiptItem)
                    }
                ],
                category: receiptItem.category
            };
            let itemIndex = newItems.findIndex((it) => it.name === receiptItem.name);
            if (itemIndex === -1) {
                newItems.push(newItem);
            } else {
                newItems[itemIndex] = newItem;
            }
        }
        newDatabase.push({
            store: receipt.store.name,
            items: newItems
        });
    } else {
        // replace the old database
        let newStoreDatabase = Object.assign({}, newDatabase[index]);
        console.log(newStoreDatabase);
        let newItems = newStoreDatabase.items.slice();
        let receiptItems = receipt.items;
        for (let i = 0; i < receiptItems.length; i++) {
            let receiptItem = receiptItems[i];
            let itemIndex = newItems.findIndex((it) => it.name === receiptItem.name);
            if (itemIndex === -1) {
                // create a new item
                let newItem = {
                    number: receiptItem.number,
                    name: receiptItem.name,
                    prices: [
                        {
                            date: receipt.date,
                            price: getPricePerOne(receiptItem)
                        }
                    ],
                    category: receiptItem.category
                };
                newItems.push(newItem);
            } else {
                // replace the old item
                let newItem = Object.assign({}, newItems[itemIndex]);
                console.log(newItem);
                let newPrices = newItem.prices.slice();
                let priceIndex = newPrices.findIndex((p) => p.date === receipt.date);
                if (priceIndex === -1) {
                    // if there is no other price entry at this date
                    newPrices.push({
                        date: receipt.date,
                        price: getPricePerOne(receiptItem)
                    });
                } else {
                    newPrices[priceIndex] = {
                        date: receipt.date,
                        price: getPricePerOne(receiptItem)
                    };
                }
                newItem.number = receiptItem.number;
                newItem.category = receiptItem.category;
                newItem.prices = newPrices;
                newItems[itemIndex] = newItem;
            }
        }
        newStoreDatabase.items = newItems;
        newDatabase[index] = newStoreDatabase;
    }
    return newDatabase;
}

/**
 *
 * @param {Item} receiptItem
 * @returns {number}
 */
function getPricePerOne(receiptItem) {
    if (receiptItem.unit_price !== null) {
        return receiptItem.unit_price;
    } else if (receiptItem.price_per_si_unit !== null) {
        return receiptItem.price_per_si_unit;
    } else {
        return receiptItem.total_price;
    }
}