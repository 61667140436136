import React from 'react';
import {ProgressSpinner} from "primereact/progressspinner";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {BlockUI} from "primereact/blockui";
import FeedbackInputText from "../input_elements/FeedbackInputText";
import ProductCardList from "./ProductCardList";
import FeedbackInputDate from "../input_elements/FeedbackInputDate";
import {getFieldFeedbackByName, getFieldFeedbacks, getItemFeedbacks} from "../../data/FeedbackUtil";

import {withTranslation} from "react-i18next";
import {ReceiptProcessStatus} from "../../data/ProcessHelper";
import ProductTable from "./ProductTable";
import {confirmDialog, ConfirmDialog} from "primereact/confirmdialog";
import DebugMenu from "./DebugMenu";
import {InputTextarea} from "primereact/inputtextarea";
import LegalPageBottom from "../LegalPageBottom";

class ReceiptView extends React.Component {
    constructor(props) {
        super(props);

        this.maxImageHeight = 600;
        this.maxImageWidth = 300;

        this.state = {
            photoWidth: 50,
            photoHeight: 50,
            baselineWidth: 50,
            baselineHeight: 50,

            addProductMode: false,

            showImage: false,
            showBaselines: false,
            showPlainText: false
        }
    }

    calculatePhotoSize() {
        let photoImage = new Image();
        let self = this;
        photoImage.onload = function () {
            let widthBasedOnHeight = Math.round(this.width * (self.maxImageHeight / this.height));
            let heightBasedOnHeight = self.maxImageHeight;

            let widthBasedOnWidth = self.maxImageWidth;
            let heightBasedOnWidth = Math.round(this.height * (self.maxImageWidth / this.width));

            if (widthBasedOnHeight <= self.maxImageWidth) {
                self.setState({
                    photoWidth: widthBasedOnHeight,
                    photoHeight: heightBasedOnHeight
                });
            } else {
                self.setState({
                    photoWidth: widthBasedOnWidth,
                    photoHeight: heightBasedOnWidth
                });
            }

        };
        photoImage.src = this.props.imageUrl;
    }

    calculateBaselinesSize() {
        let baselinesImage = new Image();
        let self = this;
        baselinesImage.onload = function () {
            let widthBasedOnHeight = Math.round(this.width * (self.maxImageHeight / this.height));
            let heightBasedOnHeight = self.maxImageHeight;

            let widthBasedOnWidth = self.maxImageWidth;
            let heightBasedOnWidth = Math.round(this.height * (self.maxImageWidth / this.width));

            if (widthBasedOnHeight <= self.maxImageWidth) {
                self.setState({
                    baselinesWidth: widthBasedOnHeight,
                    baselinesHeight: heightBasedOnHeight
                });
            } else {
                self.setState({
                    baselinesWidth: widthBasedOnWidth,
                    baselinesHeight: heightBasedOnWidth
                });
            }

        };
        baselinesImage.src = this.props.baselinesUrl;
    }

    componentDidMount() {
        if (this.props.imageUrl !== null) {
            this.calculatePhotoSize();
        }
        if (this.props.baselinesUrl !== null) {
            this.calculateBaselinesSize();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.imageUrl !== this.props.imageUrl && this.props.imageUrl !== null) {
            this.calculatePhotoSize();
        }
        if (prevProps.baselinesUrl !== this.props.baselinesUrl && this.props.baselinesUrl !== null) {
            this.calculateBaselinesSize();
        }
    }

    renderProgress(process) {
        const t = this.props.t;
        if (process.status === ReceiptProcessStatus.WAIT_FOR_RESULT) {
            let latestFinishedStep = process.latestFinishedStep;
            if (latestFinishedStep === null || latestFinishedStep === "input") {
                return <>{"(1/10) " + t("receipts.sortFiles")}</>;
            } else if (latestFinishedStep === "split") {
                return <>{"(2/10) " + t("receipts.convertImageToPng")}</>;
            } else if (latestFinishedStep === "convert") {
                return <>{"(3/10) " + t("receipts.binarizeImage")}</>;
            } else if (latestFinishedStep === "binarize") {
                return <>{"(4/10) " + t("receipts.identifyTextRegions")}</>;
            } else if (latestFinishedStep === "segmentation") {
                return <>{"(5/10) " + t("receipts.identifyTextLines")}</>;
            } else if (latestFinishedStep === "linerecognition") {
                return <>{"(6/10) " + t("receipts.connectTextAndImageFiles")}</>;
            } else if (latestFinishedStep === "connect") {
                return <>{"(7/10) " + t("receipts.collectFiles")}</>;
            } else if (latestFinishedStep === "pipelinetestblock") {
                return <>{"(8/10) " + t("receipts.recognizeCharacters")}</>;
            } else if (latestFinishedStep === "ocr") {
                return <>{"(9/10) " + t("receipts.interpretTexts")}</>;
            } else if (latestFinishedStep === "interpret") {
                return <>{"(10/10) " + t("receipts.concatenateTexts")}</>;
            } else if (latestFinishedStep === "concatenate") {
                return <>{t("receipts.processingCompleted")}</>;
            }
        }
        return <></>;
    }

    render() {
        let process = this.getProcess();
        if (process !== null) {
            return (
                <div className="flex flex-column align-items-center justify-content-center gap-2"
                     style={{height: "calc(100vh - 5rem)"}}>
                    <ProgressSpinner/>
                    {this.renderProgress(process)}
                </div>
            );
        } else {
            return (
                <>
                    {this.renderGeneralInformation()}
                    {this.renderTable()}
                    {this.renderBottomButtons()}
                    {this.renderImageView()}
                    <LegalPageBottom bottomOffset="9rem"/>
                </>
            );
        }
    }

    renderGeneralInformation() {
        const t = this.props.t;
        let fieldFeedbacks = getFieldFeedbacks(this.props.receiptFeedback);
        let storeNameFeedback = getFieldFeedbackByName(fieldFeedbacks, "store_name");
        let dateFeedback = getFieldFeedbackByName(fieldFeedbacks, "date");
        let sumFeedback = getFieldFeedbackByName(fieldFeedbacks, "sum");
        return (
            <BlockUI blocked={this.state.addProductMode} autoZIndex={false}>
                <div className="grid m-0 px-2 pt-4 pb-2">
                    <FeedbackInputText columnType={"col-4"} name={"store_name"} label={t("receipts.storeName")}
                                       value={this.props.receiptInEdit.store.name}
                                       onChange={(newValue) => this.props.onReceiptChange(this.props.receiptId, newValue, this.props.receiptInEdit.date, this.props.receiptInEdit.sum)}
                                       feedback={storeNameFeedback}
                    />
                    <FeedbackInputDate columnType={"col-4"} name={"date"} label={t("receipts.date")}
                                       value={this.props.receiptInEdit.date}
                                       onChange={(newValue) => this.props.onReceiptChange(this.props.receiptId, this.props.receiptInEdit.store.name, newValue, this.props.receiptInEdit.sum)}
                                       feedback={dateFeedback}
                    />
                    <FeedbackInputText columnType={"col-4"} name={"sum"} label={t("receipts.sum")}
                                       value={this.props.receiptInEdit.sum}
                                       onChange={(newValue) => this.props.onReceiptChange(this.props.receiptId, this.props.receiptInEdit.store.name, this.props.receiptInEdit.date, newValue)}
                                       feedback={sumFeedback}
                    />
                </div>
            </BlockUI>
        );
    }

    renderAddProductButton() {
        const t = this.props.t;
        if (this.state.addProductMode) {
            return (
                <div className="flex justify-content-end">
                    <Button label={t("common.cancel")}
                            className="p-button-secondary p-button-rounded m-3 shadow-4 pointer-events-auto"
                            onClick={() => this.setState({addProductMode: false})}/>
                    <Button label={t("receipts.addAtTheEnd")} icon="pi pi-plus"
                            className="p-button-rounded m-3 shadow-4 pointer-events-auto"
                            onClick={() => this.onAddProductButtonClick()}/>
                </div>
            );
        } else {
            return (
                <div className="flex justify-content-between">
                    <div className="flex justify-content-start">
                        <Button label={t("receipts.photo")} icon="pi pi-external-link"
                                disabled={this.props.imageUrl === null}
                                className="p-button-rounded m-3 shadow-4 pointer-events-auto"
                                onClick={() => this.setState({showImage: true})}/>
                        <DebugMenu debug={this.props.debug}/>
                    </div>
                    <Button icon="pi pi-plus" className="p-button-rounded m-3 shadow-4 pointer-events-auto"
                            aria-label="Add product" onClick={() => this.onAddProductButtonClick()}/>
                </div>
            );
        }
    }

    onAddProductButtonClick() {
        if (this.state.addProductMode) {
            this.addProductBefore(this.props.receiptInEdit.items.length);
        } else {
            this.setState({addProductMode: true});
        }

    }

    renderBottomButtons() {
        const t = this.props.t;
        return (
            <>
                <div className="fixed block min-w-full bottom-0 z-5 pointer-events-none">
                    {this.renderAddProductButton()}
                    <div className="bg-white shadow-8 pointer-events-auto">
                        <BlockUI blocked={this.state.addProductMode}>
                            <div className="flex justify-content-between p-2">
                                <div>
                                    <Button label={t("common.delete")} icon="pi pi-trash"
                                            className="p-button-danger"
                                            onClick={() => this.confirmDelete()}/>
                                </div>
                                <div>
                                    <Button label={t("common.save")} icon="pi pi-save" className="p-button-success"
                                            onClick={() => this.validateReceipt()}/>
                                </div>
                            </div>
                        </BlockUI>
                    </div>
                </div>
                <ConfirmDialog/>
            </>
        );
    }

    getProcess() {
        for (let i = 0; i < this.props.receiptsInProcess.length; i++) {
            if (this.props.receiptId === this.props.receiptsInProcess[i].receiptId) {
                return this.props.receiptsInProcess[i];
            }
        }
        return null;
    }

    existsImage() {
        return this.props.imageUrl !== null;
    }

    renderTable() {
        return (
            <>
                <div className="hidden lg:block">
                    <ProductTable addProductMode={this.state.addProductMode} items={this.props.receiptInEdit.items}
                                  onChange={(itemId, fieldName, newValue) => this.props.onEditItem(this.props.receiptId, itemId, fieldName, newValue)}
                                  onAdd={(index) => this.addProductBefore(index)}
                                  onDelete={(itemId) => this.props.onDeleteItem(this.props.receiptId, itemId)}
                                  itemFeedbacks={getItemFeedbacks(this.props.receiptFeedback)}/>
                    <div className="p-7"/>
                    <div className="p-1"/>
                </div>
                <div className="block lg:hidden">
                    <div className="p-3">
                        <ProductCardList addProductMode={this.state.addProductMode} items={this.props.receiptInEdit.items}
                                         onChange={(itemId, fieldName, newValue) => this.props.onEditItem(this.props.receiptId, itemId, fieldName, newValue)}
                                         onAdd={(index) => this.addProductBefore(index)}
                                         onDelete={(itemId) => this.props.onDeleteItem(this.props.receiptId, itemId)}
                                         itemFeedbacks={getItemFeedbacks(this.props.receiptFeedback)}/>
                    </div>
                    <div className="p-7"/>
                    <div className="p-1"/>
                </div>
            </>
        );
    }

    renderImageView() {
        const t = this.props.t;
        return (
            <Dialog visible={this.state.showImage} onHide={() => this.setState({showImage: false})}
                    header={t("receipts.photoOfTheReceipt")} dismissableMask={true}
            >
                <div className="flex justify-content-center">
                    <img src={this.props.imageUrl} alt={t("receipts.photoOfTheReceipt")} width={this.state.photoWidth}
                         height={this.state.photoHeight}/>
                </div>
            </Dialog>
        );
    }

    addProductBefore(index) {
        this.setState({
            addProductMode: false
        });
        this.props.onAddItem(this.props.receiptId, index);
    }

    validateReceipt() {
        this.props.onSave(this.props.receiptId);
    }

    confirmDelete() {
        const t = this.props.t;
        confirmDialog({
            message: t("receipts.deleteReceiptMessage"),
            header: t("common.confirmDelete"),
            icon: "pi pi-trash",
            acceptLabel: t("common.delete"),
            rejectLabel: t("common.cancel"),
            acceptClassName: "p-button-danger",
            accept: () => this.props.onDeleteReceipt(this.props.receiptId),
            reject: () => {}
        });
    }

}

export default withTranslation()(ReceiptView);