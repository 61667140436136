
import {claimNextId} from "./IdHelper";


/**
 * Adds the newReceiptId to the newReceipt as an identifier and claims new identifiers for all the items on this
 * receipt.
 * @param {Receipt} newReceipt A receipt without identifiers.
 * @param {number} newReceiptId The new identifier that the receipt should have.
 * @returns {Receipt} A receipt with an identifier for the receipt itself and for all the items.
 */
export function addIds(newReceipt, newReceiptId) {
    newReceipt.id = newReceiptId;
    for (let i = 0; i < newReceipt.items.length; i++) {
        newReceipt.items[i].id = claimNextId();
    }
    return newReceipt;
}

/**
 * Adds the receipts of the otherReceipts list to the receipts in the oldReceipts list,
 * if they are not already present in the oldReceipts list.
 * @param {Receipt[]} oldReceipts The list of the old receipts. It should be the savedReceipts of the previous state in the App.
 * @param {Receipt[]} otherReceipts Another list of receipts, that have no identifiers.
 * @returns {Receipt[]} A new list that contains all the old receipts and the other receipts that were not already contained in the old receipts. Each other receipt has identifiers in the returned list.
 */
export function mergeReceipts(oldReceipts, otherReceipts) {
    let newReceipts = oldReceipts.slice();
    for (let i = 0; i < otherReceipts.length; i++) {
        let index = newReceipts.findIndex((r) => equalReceipts(r, otherReceipts[i]));
        if (index !== -1) {
            continue;
        }
        let newReceipt = otherReceipts[i];
        newReceipt.id = claimNextId();
        for (let j = 0; j < newReceipt.items.length; j++) {
            newReceipt.items[j].id = claimNextId();
        }
        newReceipts.push(otherReceipts[i]);
    }
    return newReceipts;
}

/**
 * Checks whether two receipts are equal but ignores the identifiers.
 * @param {Receipt} receipt1 The first receipt. (Must not necessarily have identifiers.)
 * @param {Receipt} receipt2 The second receipt. (Must not necessarily have identifiers.)
 * @returns {boolean} True, if the two receipts have equal properties. False, otherwise.
 */
function equalReceipts(receipt1, receipt2) {
    if (receipt1.store.name !== receipt2.store.name) {
        return false;
    }
    if (receipt1.sum !== receipt2.sum) {
        return false;
    }
    if (receipt1.date !== receipt2.date) {
        return false;
    }
    if (receipt1.items.length !== receipt2.items.length) {
        return false;
    }
    let itemCopy = receipt2.items.slice();
    for (let i = 0; i < receipt1.items.length; i++) {
        let index = itemCopy.findIndex((it) => equalItems(it, receipt1.items[i]));
        if (index === -1) {
            return false;
        }
        itemCopy = itemCopy.splice(index, 1);
    }
    return itemCopy.length === 0;
}

/**
 * Checks whether two items are equal but ignores the identifiers.
 * @param {Item} item1 The first item. (Must not necessarily have identifiers.)
 * @param {Item} item2 The second item. (Must not necessarily have identifiers.)
 * @returns {boolean} True, if the two items have equal properties. False, otherwise.
 */
function equalItems(item1, item2) {
    let simplePropertiesNotEqual = item1.name !== item2.name
        || item1.number !== item2.number
        || item1.per_weight !== item2.per_weight
        || item1.amount !== item2.amount
        || item1.unit_price !== item2.unit_price
        || item1.weight !== item2.weight
        || item1.si_unit !== item2.si_unit
        || item1.price_per_si_unit !== item2.price_per_si_unit
        || item1.total_price !== item2.total_price
        || item1.tax_category !== item2.tax_category
        || item1.category !== item2.category;
    return !simplePropertiesNotEqual;
}

/**
 * Adds the StoreDatabases of the otherDatabase to those of the oldDatabase. Makes sure that there are no duplicates.
 * @param {StoreDatabase[]} oldDatabase The list of the old store database objects. It should be the savedDatabase of the previous state in the App.
 * @param {StoreDatabase[]} otherDatabase Another list of store database objects.
 * @returns {StoreDatabase[]} A new list that contains the elements of the oldDatabase merged together with the elements of the otherDatabase.
 */
export function mergeDatabase(oldDatabase, otherDatabase) {
    let newDatabase = oldDatabase.slice();
    for (let i = 0; i < otherDatabase.length; i++) {
        let index = newDatabase.findIndex((s) => s.store === otherDatabase[i].store);
        if (index === -1) {
            newDatabase.push(otherDatabase[i]);
            continue;
        }
        newDatabase[index] = {
            store: otherDatabase[i].store,
            items: mergeItems(newDatabase[index].items, otherDatabase[i].items)
        }
    }
    return newDatabase;
}

/**
 * Adds the Items of the otherItems to those of the oldItems. Makes sure that there are no duplicates.
 * @param {DatabaseItem[]} oldItems The list of the old items.
 * @param {DatabaseItem[]} otherItems Another list of items.
 * @returns {DatabaseItem[]} A new list that contains the elements of the oldItems merged together with the elements of the otherItems.
 */
function mergeItems(oldItems, otherItems) {
    let newItems = oldItems.slice();
    for (let i = 0; i < otherItems.length; i++) {
        let index = newItems.findIndex((it) => it.name === otherItems[i].name);
        if (index === -1) {
            newItems.push(otherItems[i]);
            continue;
        }
        newItems[index] = {
            number: otherItems[i].number,
            name: otherItems[i].name,
            prices: mergePrices(newItems[index].prices, otherItems[i].prices),
            category: otherItems[i].category
        }
    }
    return newItems;
}

/**
 * Adds the TemporaryPrices of the otherPrices to those of the oldPrices. Makes sure that there are no duplicates.
 * @param {TemporaryPrice[]} oldPrices The list of the old temporary prices.
 * @param {TemporaryPrice[]} otherPrices Another list of temporary prices.
 * @returns {TemporaryPrice[]} A new list that contains the elements of the oldPrices merged together with the elements of the otherPrices.
 */
function mergePrices(oldPrices, otherPrices) {
    let newPrices = oldPrices.slice();
    for (let i = 0; i < otherPrices.length; i++) {
        let index = newPrices.findIndex((p) => p.date === otherPrices[i].date);
        if (index === -1) {
            newPrices.push(otherPrices[i]);
            continue;
        }
        newPrices[index] = {
            date: otherPrices[i].date,
            price: otherPrices[i].price
        }
    }
    return newPrices;
}