/**
 *
 * @param {Receipts} receipts
 * @returns {Array<TableItem>}
 */
export function convertReceiptsToTableItems(receipts) {
    let tableItems = [];
    for (let i = 0; i < receipts.length; i++) {
        let date = new Date(receipts[i].date * 1000);
        let store = receipts[i].store.name;

        for (let j = 0; j < receipts[i].items.length; j++) {
            tableItems.push({
                store: store,
                category: receipts[i].items[j].category,
                date: date,

                number: receipts[i].items[j].number,
                name: receipts[i].items[j].name,
                totalPrice: receipts[i].items[j].total_price
            });

        }
    }
    return tableItems;
}

/**
 *
 * @param {Array<TableItem>} tableItems
 * @param {Array<Date>|null} timeRange
 * @param {Array<Array<Date>>|null} timeGroups
 * @param {Array<string>|null} stores
 * @param {Array<string>|null} categories
 */
export function convertTableItemsToCategories(tableItems, timeRange, timeGroups, stores, categories) {
    if (timeRange !== null) {
        tableItems = tableItems.filter(value => timeRange[0].getTime() <= value.date.getTime() && value.date.getTime() < timeRange[1].getTime() + 1000 * 60 * 60 * 24);
    }
    let result = new Array(stores !== null ? stores.length : 1);
    for (let i = 0; i < result.length; i++) {
        result[i] = new Array(categories !== null ? categories.length : 1);
        for (let j = 0; j < result[i].length; j++) {
            result[i][j] = new Array(timeGroups !== null ? timeGroups.length : 1);
            for (let k = 0; k < result[i][j].length; k++) {
                result[i][j][k] = 0;
            }
        }
    }
    for (let i = 0; i < tableItems.length; i++) {
        let timeIndex = getTimeGroup(tableItems[i], timeGroups);
        let storeIndex = getStore(tableItems[i], stores);
        let categoryIndex = getCategory(tableItems[i], categories);

        if (timeIndex === null || storeIndex === null || categoryIndex === null) {
            continue;
        }
        result[storeIndex][categoryIndex][timeIndex] += tableItems[i].totalPrice;
    }
    for (let i = 0; i < result.length; i++) {
        for (let j = 0; j < result[i].length; j++) {
            for (let k = 0; k < result[i][j].length; k++) {
                result[i][j][k] = Math.round(result[i][j][k] * 100) / 100;
            }
        }
    }
    return result;
}

/**
 *
 * @param {TableItem} tableItem
 * @param {Array<Array<Date>>|null} timeGroups
 * @returns {number|null}
 */
function getTimeGroup(tableItem, timeGroups) {
    if (timeGroups === null) {
        return 0;
    }
    for (let i = 0; i < timeGroups.length; i++) {
        if (timeGroups[i][0].getTime() <= tableItem.date.getTime() && tableItem.date.getTime() < timeGroups[i][1].getTime()) {
            return i;
        }
    }
    return null;
}

/**
 *
 * @param {TableItem} tableItem
 * @param {Array<string>|null} stores
 * @returns {number|null}
 */
function getStore(tableItem, stores) {
    if (stores === null) {
        return 0;
    }
    for (let i = 0; i < stores.length; i++) {
        if (stores[i] === tableItem.store) {
            return i;
        }
    }
    return null;
}

/**
 *
 * @param {TableItem} tableItem
 * @param {Array<string>|null} categories
 * @returns {number|null}
 */
function getCategory(tableItem, categories) {
    if (categories === null) {
        return 0;
    }
    for (let i = 0; i < categories.length; i++) {
        if (categories[i] === tableItem.category) {
            return i;
        }
    }
    return null;
}

/**
 *
 * @param {Date} date
 */
export function getCalenderWeek(date) {
    let begin = getBeginOfFirstCalenderWeek(date.getFullYear());
    if (date.getTime() < begin.getTime()) {
        begin = getBeginOfFirstCalenderWeek(date.getFullYear() - 1);
    }
    let difference = date.getTime() - begin.getTime();
    return Math.floor(difference / (1000 * 60 * 60 * 24 * 7)) + 1;
}

/**
 *
 * @param {number} fullYear
 * @returns {Date}
 */
function getBeginOfFirstCalenderWeek(fullYear) {
    let firstOfYear = new Date(fullYear, 0, 1);
    let weekday = firstOfYear.getDay() === 0 ? 6 : firstOfYear.getDay() - 1;
    if (weekday > 3) {
        return new Date(fullYear, 0, 1 + (7 - weekday));
    } else if (weekday === 3) {
        return new Date(fullYear, 0, 1);
    } else {
        return new Date(fullYear - 1, 11, 31 - weekday + 1);
    }
}