/**
 *
 * @param {number} price
 * @returns {string}
 */
export function convertNumberToCurrencyString(price) {
    let price100 = Math.round(price * 100);
    if (price100 / 100 !== price) {
        throw new Error("Price must not have more than two decimal places. Received: " + price);
    }
    // if both decimal places are zero
    if (price100 % 100 === 0) {
        return (price100 / 100) + ",00";
    }
    // if the last decimal place is zero
    if (price100 % 10 === 0) {
        return ((price100 / 100) + "").replace(".",",") + "0";
    }
    return ((price100 / 100) + "").replace(".",",");
}

/**
 *
 * @param {string} priceString
 * @returns {number}
 */
export function convertCurrencyStringToNumber(priceString) {
    if (priceString.match(/^-?(0|[1-9]\d*),\d{2}$/g) === null) {
        throw new Error("Price string must have the correct format. Received: " + priceString);
    }
    return parseFloat(priceString.replace(",","."));
}

/**
 *
 * @param {number} number
 * @returns {string}
 */
export function convertNumberToString(number) {
    return (number + "").replace(".",",");
}

/**
 *
 * @param {string} numberString
 * @returns {number}
 */
export function convertStringToNumber(numberString) {
    if (numberString.match(/^-?((0|[1-9]\d*)(,\d+)?)$/g) === null) {
        throw new Error("Number string must have the correct format. Received: " + numberString);
    }
    return parseFloat(numberString.replace(",","."));
}

/**
 *
 * @param {number} integer
 * @returns {string}
 */
export function convertIntegerToString(integer) {
    if (Math.round(integer) !== integer) {
        throw new Error("Integer must have the correct format. Received: " + integer);
    }
    return integer + "";
}

/**
 *
 * @param {string} integerString
 * @returns {number}
 */
export function convertStringToInteger(integerString) {
    if (integerString.match(/^-?(0|[1-9]\d*)$/g) === null) {
        throw new Error("Integer string must have the correct format. Received: " + integerString);
    }
    return parseInt(integerString, 10);
}