/**
 * A script that provides basic utility functions for the work with feedbacks.
 */

/**
 * Returns the FieldFeedbacks of a ReceiptFeedback or an ItemFeedback. If the parameter is null or if there are no
 * FieldFeedbacks in the feedbacks list of the input, it returns null.
 *
 * @param {ReceiptFeedback|ItemFeedback|null} feedback The item/receipt feedback object whose FieldFeedbacks should be extracted. May be null.
 * @returns {FieldFeedback[]|null} Returns null, if there are no feedbacks either caused by the input null or caused by an empty feedbacks list. Otherwise it returns a list with all the FieldFeedback objects of the input.
 */
export function getFieldFeedbacks(feedback) {
    if (feedback === null || feedback.feedbacks === null) {
        return null;
    }
    return feedback.feedbacks.length > 0 ? feedback.feedbacks : null;
}

/**
 * Returns the ItemFeedbacks of a ReceiptFeedback. If the parameter is null or if there are no ItemFeedbacks in the
 * items list of the input, it returns null.
 *
 * @param {ReceiptFeedback|null} receiptFeedback The receipt feedback object whose ItemFeedbacks should be extracted. May be null.
 * @returns {ItemFeedback[]|null} Returns null, if there are no items either caused by the input null or caused by an empty items list. Otherwise it returns a list with all the ItemFeedback objects.
 */
export function getItemFeedbacks(receiptFeedback) {
    if (receiptFeedback === null || receiptFeedback.items === null) {
        return null;
    }
    return receiptFeedback.items.length > 0 ? receiptFeedback.items : null;
}

/**
 * Returns the FieldFeedback of a FieldFeedback list that has the same fieldName as specified in name.
 * If the FieldFeedback list is null or does not contain any FieldFeedbacks, null is returned.
 *
 * @param {FieldFeedback[]|null} fieldFeedbacks The FieldFeedback list whose elements should be checked. May be null.
 * @param {string} name The field name that the returned FieldFeedback object must have.
 * @returns {FieldFeedback|null} Returns null, if the fieldFeedbacks list is null or if there is no FieldFeedback with the specified name. Otherwise it returns the first FieldFeedback object that matches the name.
 */
export function getFieldFeedbackByName(fieldFeedbacks, name) {
    if (fieldFeedbacks === null) {
        return null;
    }
    let fieldFeedback = fieldFeedbacks.find(ff => ff.fieldName === name);
    return fieldFeedback !== undefined ? fieldFeedback : null;
}

/**
 * Returns the ItemFeedback of an ItemFeedback list that has the same itemId as the specified id.
 * If the ItemFeedback list is null or does not contain any ItemFeedback that matches the id, null is returned.
 *
 * @param {ItemFeedback[]|null} itemFeedbacks The itemFeedback list whose elements should be checked. May be null.
 * @param {number} id The itemId that the returned ItemFeedback object must have.
 * @returns {ItemFeedback|null} Returns null, if the itemFeedbacks list is null or if there is no ItemFeedback with the specified id. Otherwise it returns the (first) ItemFeedback object that matches the id.
 */
export function getItemFeedbackById(itemFeedbacks, id) {
    if (itemFeedbacks === null) {
        return null;
    }
    let itemFeedback = itemFeedbacks.find(itfe => itfe.itemId === id);
    return itemFeedback !== undefined ? itemFeedback : null;
}