import React from 'react';
import AggregateFilter from "./AggregateFilter";
import AggregateTable from "./AggregateTable";
import {convertReceiptsToTableItems, convertTableItemsToCategories} from "./TableItemUtils";
import {
    getDayOfWeek,
    getDifferenceInDays,
    getNextDay,
    getNextFirstOfMonth,
    getNextMonday,
    getThisDay, getThisFirstOfMonth,
    getThisMonday
} from "./DateUtil";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";

import {withTranslation} from "react-i18next";
import TimeGroupingType from "./TimeGroupingType";
import LegalPageBottom from "../LegalPageBottom";

class AggregateView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            filter: {
                timeRange: {
                    activated: false,
                    value: [
                        new Date(2020, 0, 1),
                        getThisDay(new Date())
                    ]
                },
                groupTime: {
                    activated: false,
                    value: TimeGroupingType.MONTH
                },
                stores: {
                    activated: false,
                    value: []
                },
                categories: {
                    activated: false,
                    value: []
                }
            },

            timeRange: null,

            stores: null,
            categories: null,
            timeGroups: null,
            groupTime: null,

            originalData: null,
            aggregatedData: null,

            allStores: [],
            allCategories: [],

            loading: false,
            controlPanelVisible: false
        }
    }

    updateView() {
        let json = this.props.savedReceipts;
        let aggregatedData = convertTableItemsToCategories(convertReceiptsToTableItems(json), this.state.timeRange, this.state.timeGroups, this.state.stores, this.state.categories);
        this.setState({originalData: json, aggregatedData: aggregatedData, loading: false});
    }

    updateAllStores() {
        let stores = [];
        for (let i = 0; i < this.props.savedReceipts.length; i++) {
            let receipt = this.props.savedReceipts[i];
            if (receipt.store !== null && receipt.store.name !== null) {
                if (!stores.includes(receipt.store.name)) {
                    stores.push(receipt.store.name);
                }
            }
        }
        let selectedStores = this.state.stores !== null ? this.state.stores.filter(s => stores.includes(s)) : null;
        if (this.state.stores !== null && selectedStores.length === 0) {
            selectedStores = null;
        }
        stores.sort((a,b) => a.localeCompare(b));
        this.setState({allStores: stores, stores: selectedStores});
    }

    updateAllCategories() {
        let categories = [];
        for (let i = 0; i < this.props.savedReceipts.length; i++) {
            let receipt = this.props.savedReceipts[i];
            if (receipt.items === null) {
                continue;
            }
            for (let j = 0; j < receipt.items.length; j++) {
                let item = receipt.items[j];
                if (item.category !== null) {
                    if (!categories.includes(item.category)) {
                        categories.push(item.category);
                    }
                }
            }
        }
        let selectedCategories = this.state.categories !== null ? this.state.categories.filter(c => categories.includes(c)) : null;
        if (this.state.categories !== null && selectedCategories.length === 0) {
            selectedCategories = null;
        }
        categories.sort((a,b) => a.localeCompare(b));
        this.setState({allCategories: categories, categories: selectedCategories});
    }

    initialiseFilter() {
        let json = this.props.savedReceipts;
        let stores = [];
        let earliestDate = new Date();
        for (let i = 0; i < json.length; i++) {
            if (!stores.includes(json[i].store.name) && json[i].store.name !== null) {
                stores.push(json[i].store.name)
            }
            if (json[i].date * 1000 < earliestDate.getTime()) {
                earliestDate = new Date(json[i].date * 1000);
            }
        }
        this.changeFilter({
            timeRange: {
                activated: true,
                value: [
                    getThisDay(earliestDate),
                    getThisDay(new Date())
                ]
            },
            groupTime: {
                activated: true,
                value: TimeGroupingType.MONTH
            },
            stores: {
                activated: true,
                value: stores
            },
            categories: {
                activated: false,
                value: null
            }
        });
    }

    calculateTimeGroups(timeRange, groupBy) {
        let timeGroups = [];
        let start = timeRange[0];
        let end = timeRange[1];
        if (groupBy === TimeGroupingType.DAY) {
            let current = getThisDay(start);
            while (current.getTime() <= end.getTime()) {
                let rangeStart = current;
                let rangeEnd = getNextDay(current);
                timeGroups.push([rangeStart, rangeEnd]);
                current = rangeEnd;
            }
        } else if (groupBy === TimeGroupingType.WEEK) {
            if (getDifferenceInDays(end, start) < 7 && getDayOfWeek(start) < getDayOfWeek(end)) {
                timeGroups.push([start, getNextDay(end)]);
            } else {
                let firstWeekEnd = getNextMonday(start);
                let lastWeekStart = getThisMonday(end);
                timeGroups.push([start, firstWeekEnd]);
                let current = firstWeekEnd;
                while (current.getTime() < lastWeekStart.getTime()) {
                    let rangeStart = current;
                    let rangeEnd = getNextMonday(current);
                    timeGroups.push([rangeStart, rangeEnd]);
                    current = rangeEnd;
                }
                timeGroups.push([lastWeekStart, getNextDay(end)]);
            }
        } else if (groupBy === TimeGroupingType.MONTH) {
            let monthStart = start.getMonth();
            let monthEnd = end.getMonth();
            if (start.getFullYear() === end.getFullYear() && monthStart === monthEnd) {
                timeGroups.push([start, getNextDay(end)]);
            } else {
                let firstMonthEnd = getNextFirstOfMonth(start);
                let lastMonthStart = getThisFirstOfMonth(end);
                timeGroups.push([start, firstMonthEnd]);
                let current = firstMonthEnd;
                while (current.getTime() < lastMonthStart.getTime()) {
                    let rangeStart = current;
                    let rangeEnd = getNextFirstOfMonth(current);
                    timeGroups.push([rangeStart, rangeEnd]);
                    current = rangeEnd;
                }
                timeGroups.push([lastMonthStart, getNextDay(end)]);
            }
        }
        return timeGroups;
    }

    changeFilter(filter) {
        this.setState({filter: filter});

        let timeRange = null;
        if (filter.timeRange.activated) {
            let filterTimeRange = filter.timeRange.value;
            if (filterTimeRange !== null && filterTimeRange.length === 2) {
                if (filterTimeRange[0] !== null && filterTimeRange[1] !== null) {
                    timeRange = filterTimeRange;
                }
            }
        }

        let groupTime = null;
        let timeGroups = null;
        if (filter.groupTime.activated && timeRange !== null) {
            if (filter.groupTime.value === TimeGroupingType.MONTH || filter.groupTime.value === TimeGroupingType.WEEK || filter.groupTime.value === TimeGroupingType.DAY) {
                timeGroups = this.calculateTimeGroups(timeRange, filter.groupTime.value);
                groupTime = filter.groupTime.value;
            }
        }

        let stores = null;
        if (filter.stores.activated) {
            if (filter.stores.value !== null && filter.stores.value.length > 0) {
                stores = filter.stores.value;
            }
        }

        let categories = null;
        if (filter.categories.activated) {
            if (filter.categories.value !== null && filter.categories.value.length > 0) {
                categories = filter.categories.value;
            }
        }


        this.setState(prevState => {
            let aggregatedData = convertTableItemsToCategories(convertReceiptsToTableItems(prevState.originalData), timeRange, timeGroups, stores, categories);
            return {
                timeRange: timeRange,
                timeGroups: timeGroups,
                groupTime: groupTime,
                stores: stores,
                categories: categories,
                aggregatedData: aggregatedData
            };
        });
    }

    componentDidMount() {
        this.setState({loading: true});
        this.updateAllStores();
        this.updateAllCategories();
        this.updateView();
        this.initialiseFilter();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.savedReceipts !== this.props.savedReceipts) {
            this.setState({loading: true});
            this.updateAllStores();
            this.updateAllCategories();
            this.updateView();
        }
    }

    render() {
        const t = this.props.t;
        return (
            <>
                <div className="shadow-8 pl-4 pt-1 pb-1 mt-4 mb-4" style={{backgroundColor: "var(--primary-color)"}}>
                    <h1 className="text-0">
                        {t("statistics.overviewPurchases")}
                    </h1>
                </div>
                <AggregateTable stores={this.state.stores} categories={this.state.categories}
                                timeGroups={this.state.timeGroups} data={this.state.aggregatedData}
                                groupTime={this.state.groupTime} loading={this.state.loading}/>
                <Dialog visible={this.state.controlPanelVisible}
                        onHide={() => this.setState({controlPanelVisible: false})} header={t("statistics.groupSettings")}>
                    <AggregateFilter filter={this.state.filter} onChange={(filter) => this.changeFilter(filter)} allStores={this.state.allStores} allCategories={this.state.allCategories}/>
                </Dialog>
                <div className="fixed block min-w-full bg-white bottom-0 shadow-8 z-5">
                    <div className="flex justify-content-end p-2">
                        <div>
                            <Button label={t("statistics.groupSettings")} icon="pi pi-external-link"
                                    onClick={() => this.setState({controlPanelVisible: true})}/>
                        </div>
                    </div>
                </div>
                <div className="p-5"/>
                <LegalPageBottom bottomOffset="5rem"/>
            </>
        );
    }
}

export default withTranslation()(AggregateView);