import React from 'react';
import {Checkbox} from "primereact/checkbox";

class FilterOption extends React.Component {
    render() {
        return (
            <>
                <div className="flex justify-content-between align-items-center mt-3">
                    {this.props.name}
                    <Checkbox checked={this.props.enabled} onChange={e => this.props.onChange(e.checked)}/>
                </div>
                <div className="mt-2">
                    {this.props.children}
                </div>
            </>
        );
    }
}

export default FilterOption;