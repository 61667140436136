import React from 'react';
import PropTypes from "prop-types";
import {InputText} from "primereact/inputtext";


class FeedbackInputText extends React.Component {
    // props

    existsFeedback() {
        return this.props.feedback !== undefined && this.props.feedback !== null;
    }

    getIcon() {
        let severity = this.props.feedback.severity;
        if (severity === "info") {
            return "pi-info-circle";
        } else if (severity === "warning") {
            return "pi-exclamation-triangle";
        } else if (severity === "error") {
            return "pi-exclamation-circle";
        }
        throw new Error("Invalid severity value \"" + severity + "\".");
    }

    getColor() {
        let severity = this.props.feedback.severity;
        if (severity === "info") {
            return "cyan-500";
        } else if (severity === "warning") {
            return "orange-500";
        } else if (severity === "error") {
            return "red-500";
        }
    }

    renderFeedback() {
        if (!this.existsFeedback()) {
            return (<></>);
        }
        return (
            <div className="flex justify-content-start align-items-center p-2 gap-2">
                <i className={"pi " + this.getIcon() + " text-" + this.getColor()}/>
                <small className={"text-" + this.getColor()}>{this.props.feedback.message}</small>
            </div>
        );

    }

    render() {
        return (
            <div className={this.props.columnType + " field mt-3"}>
                <span className="p-float-label">
                    <InputText id={"input-" + this.props.name} value={this.props.value}
                               className={"w-full" + (this.existsFeedback() ? " border-" + this.getColor() : "")}
                               onChange={(e) => this.props.onChange(e.target.value)}
                    />
                    <label htmlFor={"input-" + this.props.name}>{this.props.label}</label>
                </span>
                {this.renderFeedback()}
            </div>
        );
    }
}

FeedbackInputText.propTypes = {
    columnType: PropTypes.oneOf(["col-1", "col-2", "col-3", "col-4", "col-5", "col-6", "col-7", "col-8", "col-9", "col-10", "col-11", "col-12"]).isRequired,
    name: PropTypes.string.isRequired, // the key of the inputtext object
    label: PropTypes.string.isRequired, // the label of the inputtext object
    value: PropTypes.string.isRequired, // the value of the inputtext object
    onChange: PropTypes.func.isRequired, // what happens if the value is changed by the user
    feedback: PropTypes.shape({ // an optional feedback
        severity: PropTypes.oneOf(["info", "warning", "error"]).isRequired,
        message: PropTypes.string.isRequired
    })
}

export default FeedbackInputText;