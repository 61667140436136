import React from 'react';
import {Sidebar} from "primereact/sidebar";
import PageType from "./PageType";
import {Accordion, AccordionTab} from "primereact/accordion";
import {ListBox} from "primereact/listbox";

import {withTranslation} from "react-i18next";

class SidebarMenu extends React.Component {

    renderHeader(type, text) {
        if (this.props.openedPage.type === type) {
            return <span className="font-bold">{text}</span>
        } else {
            return <span className="font-normal">{text}</span>
        }
    }

    render() {
        const t = this.props.t;
        let [receiptButtons, selectedButton] = this.getReceiptButtons();
        return (
            <Sidebar visible={this.props.visible} onHide={this.props.onHide} blockScroll={true}>
                <Accordion multiple activeIndex={this.props.activeIndices} onTabChange={(e) => this.props.onActiveIndicesChange(e.index)}>
                    <AccordionTab header={this.renderHeader(PageType.START, t("start.title"))}/>
                    <AccordionTab header={this.renderHeader(PageType.RECEIPT, t("receipts.title"))} disabled={receiptButtons.length <= 0}>
                        <ListBox options={receiptButtons} value={selectedButton} itemTemplate={(item) => this.itemTemplate(item)}
                                 onChange={(e) => this.props.onReceiptSelect(e.value !== null ? e.value.receiptId : null)}
                                 listStyle={{maxHeight: "calc(100vh - 24rem)"}}
                        />
                    </AccordionTab>
                    <AccordionTab header={this.renderHeader(PageType.STATISTIC, t("statistics.title"))}/>
                    <AccordionTab header={this.renderHeader(PageType.DATA_ADMINISTRATION, t("dataAdministration.title"))}/>
                </Accordion>
            </Sidebar>
        );
    }

    getReceiptButtons() {
        let selectedButton = null;
        let buttons = [];
        for (let i = 0; i < this.props.savedReceipts.length; i++) {
            let receipt = this.props.savedReceipts[i];
            if (receipt.date !== null) {
                buttons.push({
                    receiptId: receipt.id,
                    date: receipt.date,
                    text: this.formatDate(receipt.date) + (receipt.store.name !== null ? " " + receipt.store.name : "")
                });
            } else {
                buttons.push({
                    receiptId: receipt.id,
                    date: null,
                    text: "??.??.????" + (receipt.store.name !== null ? " " + receipt.store.name : "")
                });
            }
        }
        for (let i = 0; i < this.props.receiptsInProcess.length; i++) {
            let receipt = this.props.receiptsInProcess[i];
            buttons.push({
                receiptId: receipt.receiptId,
                date: null,
                text: "Transcribe..."
            });
        }
        buttons.sort((b1, b2) => {
            if (b1.date === null && b2.date === null) {
                return 0;
            } else if (b1.date === null) {
                return -1;
            } else if (b2.date === null) {
                return 1;
            } else {
                return b2.date - b1.date;
            }
        });
        if (this.props.openedPage.type === PageType.RECEIPT) {
            let button = buttons.find((b) => b.receiptId === this.props.openedPage.receiptId);
            if (button !== undefined) {
                selectedButton = button;
            }
        }
        return [buttons, selectedButton];
    }

    formatDate(timestamp) {
        let date = new Date(timestamp * 1000);
        let dateString = (date.getDate() < 10 ? "0" : "") + date.getDate();
        let monthString = (date.getMonth() + 1 < 10 ? "0" : "") + (date.getMonth() + 1);
        let yearString = "" + date.getFullYear();

        return dateString
            + "." + monthString
            + "." + yearString
    }

    itemTemplate(item) {
        const t = this.props.t;
        if (item.text === "Transcribe...") {
            return (
                <div className="flex justify-content-start align-items-center gap-2">
                    <span>{t("receipts.transcribe")}</span>
                    <i className="pi pi-spin pi-spinner"/>
                </div>
            );
        } else {
            return (
                <div className="flex justify-content-start align-items-center">
                    <span>{item.text}</span>
                </div>
            );
        }
    }
}

export default withTranslation()(SidebarMenu);