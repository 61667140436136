const protocol = null;
const backendIp = null;
const backendPort = null;

export function requestStartOfTranscription(imageFile, database, dataUsageAllowed) {
    return new Promise((resolve, reject) => {
        let databaseFile = new Blob([JSON.stringify(database)], {
            type: "application/json"
        });
        let formData = new FormData();
        formData.append("image_file", imageFile, imageFile.name);
        formData.append("storage_file", databaseFile, "item_storage.json");
        formData.append("data_usage_allowed", dataUsageAllowed)
        fetch(getUrlStart() + "api/create_new_receipt_project/", {
            method: "POST",
            headers: {},
            body: formData
        })
            .then(response => response.json())
            .then(json => resolve(json))
            .catch(error => reject(error));
    });
}

export function requestResult(projectId) {
    return new Promise((resolve, reject) => {
        fetch(getUrlStart() + "api/get_receipt_results/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8"
            },
            body: JSON.stringify({project_id: projectId})
        })
            .then(response => response.json())
            .then(json => resolve(json))
            .catch(error => reject(error));
    });
}

function getUrlStart() {
    if (protocol === null || backendIp === null || backendPort === null) {
        return "/";
    }
    return protocol + "://" + backendIp + ":" + backendPort + "/";
}