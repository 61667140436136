/**
 * Returns a new date that represents the same day as the received date.
 *
 * @param {Date} date The date for which the new date is calculated.
 * @returns {Date} A new date object that represents the same day as the received date. (At 00:00.)
 */
export function getThisDay(date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

/**
 * Returns a new date that represents the next day after the received date.
 *
 * @param {Date} date The date for which the next day is calculated.
 * @returns {Date} A new date object that represents the next day after the received date. (At 00:00)
 */
export function getNextDay(date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
}

/**
 * Returns a new date that represents the last monday before the received date.
 *
 * @param {Date} date The date for which the last monday is calculated.
 * @returns {Date} A new date object that represents the last monday before the received date. If the received date is already a monday, a new date object is returned that represents the same day. (At 00:00)
 */
export function getThisMonday(date) {
    let weekday = getDayOfWeek(date);
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() - weekday);
}

/**
 * Returns a new date that represents the next monday after the received date.
 *
 * @param {Date} date The date for which the next monday is calculated.
 * @returns {Date} A new date object that represents the next monday after the received date. If the received date is already a monday, a new date object is returned that represents the next monday (one week later). (At 00:00)
 */
export function getNextMonday(date) {
    let weekday = getDayOfWeek(date);
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() + (7 - weekday));
}

/**
 * Returns a new date which represents the first day of the month of the received date.
 *
 * @param {Date} date The date for which the first day of the month should be calculated.
 * @returns {Date} A new date object that represents the first day of the month of the received date. (At 00:00)
 */
export function getThisFirstOfMonth(date) {
    return new Date(date.getFullYear(), date.getMonth(), 1);
}

/**
 * Returns a new date which represents the first day of the next month after the received date.
 *
 * @param {Date} date The date for which the first day of the next month should be calculated.
 * @returns {Date} A new date object that represents the first day of the next month after the received date. (At 00:00)
 */
export function getNextFirstOfMonth(date) {
    return new Date(date.getFullYear(), date.getMonth() + 1, 1);
}

/**
 * Returns a new date which represents the first day of the year of the received date.
 *
 * @param {Date} date The date for which the first day of the year should be calculated.
 * @returns {Date} A new date object that represents the first day of the year of the received date. (At 00:00)
 */
export function getThisFirstOfYear(date) {
    return new Date(date.getFullYear(), 0, 1);
}

/**
 * Returns a new date which represents the first day of the next year after the received date.
 *
 * @param {Date} date The date for which the first day of the next year should be calculated.
 * @returns {Date} A new date object that represents the first day of the next year after the received date. (At 00:00)
 */
export function getNextFirstOfYear(date) {
    return new Date(date.getFullYear() + 1, 0, 1);
}

/**
 * Returns the number of the day of the week of the received date. 0=Monday, 6=Sunday.
 *
 * @param {Date} date The date of which the day of the week should be calculated.
 * @returns {number} The number of the day of the week. Is always between 0 and 6, both included.
 */
export function getDayOfWeek(date) {
    return date.getDay() === 0 ? 6 : date.getDay() - 1;
}

/**
 * Returns the number of the day of the year of the received date. 1 = January 1st
 * @param {Date} date The date of which the day of the year should be calculated.
 * @returns {number} The number of the day of the year. Is always between 1 and 365/366, both included (depending whether the date is in a leap year).
 */
export function getDayOfYear(date) {
    return getDifferenceInDays(date, getThisFirstOfYear(date)) + 1;
}

/**
 * Returns the number of days between the two received dates.
 *
 * @param {Date} minuend The date of which the subtrahend is removed.
 * @param {Date} subtrahend The date which is removed from the minuend.
 * @returns {number} The difference in days between the two dates. A positive value, if the minuend is greater than the subtrahend.
 */
export function getDifferenceInDays(minuend, subtrahend) {
    return Math.round((minuend.getTime() - subtrahend.getTime()) / (1000 * 60 * 60 * 24));
}

/**
 * Returns the number of the calender week of the received date.
 * @param {Date} date The date whose calender week should be calculated.
 * @returns {number} The number of the calender week of the received date. Is always between 1 and 52/53 (both included).
 */
export function getCalenderWeek(date) {
    let thisMonday = getThisMonday(date);
    let year = date.getFullYear() + 1;

    let beginOfFirstCalenderWeek;
    let difference;
    do {
        beginOfFirstCalenderWeek = getBeginOfCalenderWeekNumeration(year);
        difference = getDifferenceInDays(thisMonday, beginOfFirstCalenderWeek);
        year--;
    } while (difference < 0);

    return (difference / 7) + 1;
}

/**
 * Returns a date that represents the monday of the first calender week in the received year.
 * @param {number} year The year whose calender weeks should be considered.
 * @returns {Date} A new date object that represents the monday of the first calender week in the received year. Might be in the year before the received one.
 */
export function getBeginOfCalenderWeekNumeration(year) {
    let firstOfYear = new Date(year, 0, 1);
    let weekday = getDayOfWeek(firstOfYear);
    if (weekday <= 4) {
        return new Date(year, 0, 1 - weekday);
    } else {
        return new Date(year, 0, 1 + (7 - weekday));
    }
}

/**
 * Returns the name of the day of week.
 * @param {number} dayOfWeek The number of the day of week whose name should be returned. Must be between 0 (Mo) and 6 (So) both included.
 * @param {function} t The translation function.
 * @returns {string} The abbreviation of the day of week. Has always two characters.
 */
export function getNameOfDayOfWeek(dayOfWeek, t) {
    let weekday;
    switch (dayOfWeek) {
        case 0: weekday = t("statistics.daysOfWeek.monday"); break;
        case 1: weekday = t("statistics.daysOfWeek.tuesday"); break;
        case 2: weekday = t("statistics.daysOfWeek.wednesday"); break;
        case 3: weekday = t("statistics.daysOfWeek.thursday"); break;
        case 4: weekday = t("statistics.daysOfWeek.friday"); break;
        case 5: weekday = t("statistics.daysOfWeek.saturday"); break;
        case 6: weekday = t("statistics.daysOfWeek.sunday"); break;
        default: weekday = "";
    }
    return weekday;
}

/**
 * Returns the name of the month.
 * @param {number} month The number of the month whose name should be returned. Must be between 0 (Jan) and 11 (Dec) both included.
 * @param {function} t The translation function.
 * @returns {string} The abbreviation of the day of week. Has always three characters.
 */
export function getNameOfMonth(month, t) {
    let monthName;
    switch (month) {
        case 0: monthName = t("statistics.months.january"); break;
        case 1: monthName = t("statistics.months.february"); break;
        case 2: monthName = t("statistics.months.march"); break;
        case 3: monthName = t("statistics.months.april"); break;
        case 4: monthName = t("statistics.months.may"); break;
        case 5: monthName = t("statistics.months.june"); break;
        case 6: monthName = t("statistics.months.july"); break;
        case 7: monthName = t("statistics.months.august"); break;
        case 8: monthName = t("statistics.months.september"); break;
        case 9: monthName = t("statistics.months.october"); break;
        case 10: monthName = t("statistics.months.november"); break;
        case 11: monthName = t("statistics.months.december"); break;
        default: monthName = "";
    }
    return monthName;
}

export function formatDate(date) {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear() % 100;
    let dayString = day < 10 ? "0" + day : day;
    let monthString = month < 10 ? "0" + month : month;
    let yearString = year < 10 ? "0" + year : year;
    return dayString + "." + monthString + "." + yearString;
}