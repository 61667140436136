import {NameInDatabase, NumberInDatabase, PriceInDatabase} from "./ValidationEnums";
import {
    convertCurrencyStringToNumber,
    convertNumberToCurrencyString,
    convertStringToInteger,
    convertStringToNumber
} from "./ConvertUtil";

/**
 *
 * @param {StoreDatabase[]} database
 * @param {string} storeName
 * @param {Date|null} date
 * @param {StringItem} item
 * @returns {Validation}
 */
export function validateItem(database, storeName, date, item) {
    if (storeName === "") {
        return {
            nameInDatabase: NameInDatabase.NO_STORE_NAME,
            numberInDatabase: NumberInDatabase.NO_STORE_NAME,
            priceInDatabase: PriceInDatabase.NO_STORE_NAME
        }
    }
    let storeDatabase = database.find((sd) => sd.store === storeName);
    if (storeDatabase === undefined) {
        return {
            nameInDatabase: NameInDatabase.NO_DATABASE,
            numberInDatabase: NumberInDatabase.NO_DATABASE,
            priceInDatabase: PriceInDatabase.NO_DATABASE
        }
    }
    // name
    let items = storeDatabase.items;
    let databaseItem = items.find((i) => i.name === item.name);
    if (databaseItem === undefined) {
        return {
            nameInDatabase: NameInDatabase.FALSE,
            numberInDatabase: NumberInDatabase.NAME_NOT_IN_DATABASE,
            priceInDatabase: PriceInDatabase.NAME_NOT_IN_DATABASE
        }
    }
    // number
    let numberInDatabase;
    let numbersInDatabase = items.some((i) => i.number !== null);
    let itemNumber = parseInt(item.number, 10);
    if (item.number === "" && numbersInDatabase) {
        numberInDatabase = NumberInDatabase.NO_NUMBER;
    } else if (item.number !== "" && !numbersInDatabase) {
        numberInDatabase = NumberInDatabase.NO_NUMBERS_IN_DATABASE;
    } else if (item.number !== "" && itemNumber !== databaseItem.number) {
        numberInDatabase = NumberInDatabase.NUMBER_DOES_NOT_MATCH;
    } else {
        numberInDatabase = NumberInDatabase.TRUE;
    }
    // price
    let priceInDatabase;
    let pricePerOne = getPricePerOne(item);
    if (date === null) {
        priceInDatabase = PriceInDatabase.NO_DATE;
    } else if (pricePerOne === "") {
        priceInDatabase = PriceInDatabase.NO_PRICE;
    } else {
        let itemPrice = parseFloat(pricePerOne.replace(",","."));
        let fittingPrices = getMostFittingPrices(databaseItem.prices, date);
        let found = false;
        for (let i = 0; i < fittingPrices.length; i++) {
            if (fittingPrices[i].price === itemPrice) {
                found = true;
                break;
            }
        }
        if (found) {
            priceInDatabase = PriceInDatabase.TRUE;
        } else {
            priceInDatabase = PriceInDatabase.PRICE_DOES_NOT_MATCH;
        }
    }
    return {
        nameInDatabase: NameInDatabase.TRUE,
        numberInDatabase: numberInDatabase,
        priceInDatabase: priceInDatabase
    }
}

/**
 *
 * @param {TemporaryPrice[]} temporaryPrices
 * @param {Date} date
 * @returns {TemporaryPrice[]}
 */
function getMostFittingPrices(temporaryPrices, date) {
    let before = null;
    let beforeDifference = Number.POSITIVE_INFINITY;
    let after = null;
    let afterDifference = Number.NEGATIVE_INFINITY;

    let dateTimestamp = date.getTime();
    for (let i = 0; i < temporaryPrices.length; i++) {
        let entryTimestamp = temporaryPrices[i].date * 1000;
        let difference = dateTimestamp - entryTimestamp;
        if (difference >= 0 && difference < beforeDifference) {
            before = temporaryPrices[i];
            beforeDifference = difference;
        }
        if (difference <= 0 && difference > afterDifference) {
            after = temporaryPrices[i];
            afterDifference = difference;
        }
    }

    if (before === null && after === null) {
        return [];
    } else if (before !== null && after === null) {
        return [before];
    } else if (before === null && after !== null) {
        return [after];
    } else {
        return [before, after];
    }
}


/**
 *
 * @param {StringItem} item
 * @returns {string}
 */
function getPricePerOne(item) {
    if (item.unit_price !== "") {
        return item.unit_price;
    } else if (item.price_per_si_unit !== "") {
        return item.price_per_si_unit;
    } else {
        return item.total_price;
    }
}

/* ################################################################################################################## */

/**
 *
 * @param {StoreDatabase[]} database
 * @param {StringReceipt} receipt
 * @param {Function} t
 * @returns {ReceiptFeedback|null}
 */
export function generateFeedback(database, receipt, t) {
    // data type errors
    let receiptFeedback = {
        receiptId: receipt.id,
        feedbacks: [],
        items: []
    };
    check(receipt.store.name, isEmpty, receiptFeedback, {fieldName: "store_name", severity: "error", message: t("receipts.feedback.storeNameNotEmpty")});
    check(receipt.date, v => v === null, receiptFeedback, {fieldName: "date", severity: "error", message: t("receipts.feedback.dateNotEmpty")});
    check(receipt.sum, isEmpty, receiptFeedback, {fieldName: "sum", severity: "error", message: t("receipts.feedback.sumNotEmpty")});
    check(receipt.sum, not(isCurrency), receiptFeedback, {fieldName: "sum", severity: "error", message: t("receipts.feedback.sumNumberWithTwoDecimalPlaces")});
    for (let i = 0; i < receipt.items.length; i++) {
        let item = receipt.items[i];
        let itemFeedback = {
            itemId: item.id,
            feedbacks: []
        };
        // check field combinations
        checkMultiple(
            [item.amount, item.unit_price, item.weight, item.si_unit, item.price_per_si_unit],
            v => (!isEmpty(v[0]) || !isEmpty(v[1])) && (!isEmpty(v[2]) || !isEmpty(v[3]) || !isEmpty(v[4])),
            itemFeedback,
            [
                {fieldName: "amount", severity: "error", message: t("receipts.feedback.amountCombinationalEmpty")},
                {fieldName: "unit_price", severity: "error", message: t("receipts.feedback.unitPriceCombinationalEmpty")},
                {fieldName: "weight", severity: "error", message: t("receipts.feedback.weightCombinationalEmpty")},
                {fieldName: "si_unit", severity: "error", message: t("receipts.feedback.siUnitCombinationalEmpty")},
                {fieldName: "price_per_si_unit", severity: "error", message: t("receipts.feedback.pricePerSiUnitCombinationalEmpty")},
            ]
        );
        checkMultiple([item.amount, item.unit_price], v => isEmpty(v[0]) && !isEmpty(v[1]), itemFeedback, [{fieldName: "amount", severity: "error", message: t("receipts.feedback.amountCombinationalNotEmpty")}]);
        checkMultiple([item.amount, item.unit_price], v => !isEmpty(v[0]) && isEmpty(v[1]), itemFeedback, [{fieldName: "unit_price", severity: "error", message: t("receipts.feedback.unitPriceCombinationalNotEmpty")}]);
        checkMultiple([item.weight, item.si_unit, item.price_per_si_unit], v => isEmpty(v[0]) && (!isEmpty(v[1]) || !isEmpty(v[2])), itemFeedback, [{fieldName: "weight", severity: "error", message: t("receipts.feedback.weightCombinationalNotEmpty")}]);
        checkMultiple([item.weight, item.si_unit, item.price_per_si_unit], v => isEmpty(v[1]) && (!isEmpty(v[0]) || !isEmpty(v[2])), itemFeedback, [{fieldName: "si_unit", severity: "error", message: t("receipts.feedback.siUnitCombinationalNotEmpty")}]);
        checkMultiple([item.weight, item.si_unit, item.price_per_si_unit], v => isEmpty(v[2]) && (!isEmpty(v[0]) || !isEmpty(v[1])), itemFeedback, [{fieldName: "price_per_si_unit", severity: "error", message: t("receipts.feedback.pricePerSiUnitCombinationalNotEmpty")}]);
        // check field contents
        check(item.number, v => !isEmpty(v) && !isInteger(v), itemFeedback, {fieldName: "number", severity: "error", message: t("receipts.feedback.numberIntegerOrEmpty")});
        check(item.name, isEmpty, itemFeedback, {fieldName: "name", severity: "error", message: t("receipts.feedback.productNameNotEmpty")});
        check(item.amount, v => !isEmpty(v) && !isInteger(v), itemFeedback, {fieldName: "amount", severity: "error", message: t("receipts.feedback.amountIntegerOrEmpty")});
        check(item.unit_price, v => !isEmpty(v) && !isCurrency(v), itemFeedback, {fieldName: "unit_price", severity: "error", message: t("receipts.feedback.unitPriceNumberWithTwoDecimalPlacesOrEmpty")});
        check(item.weight, v => !isEmpty(v) && !isNumber(v), itemFeedback, {fieldName: "weight", severity: "error", message: t("receipts.feedback.weightNumberOrEmpty")});
        check(item.price_per_si_unit, v => !isEmpty(v) && !isCurrency(v), itemFeedback, {fieldName: "price_per_si_unit", severity: "error", message: t("receipts.feedback.pricePerSiUnitNumberWithTwoDecimalPlacesOrEmpty")});
        check(item.total_price, isEmpty, itemFeedback, {fieldName: "total_price", severity: "error", message: t("receipts.feedback.totalPriceNotEmpty")});
        check(item.total_price, not(isCurrency), itemFeedback, {fieldName: "total_price", severity: "error", message: t("receipts.feedback.totalPriceNumberWithTwoDecimalPlaces")});

        receiptFeedback.items.push(itemFeedback);
    }
    // mathematical errors
    // receipt sum
    let calculatedSum = 0.0;
    let checkSum = receiptFeedback.feedbacks.find(ff => ff.fieldName === "sum") === undefined;
    for (let i = 0; i < receipt.items.length; i++) {
        if (receiptFeedback.items[i].feedbacks.find(ff => ff.fieldName === "total_price") !== undefined) {
            checkSum = false;
            continue;
        }
        let totalPrice = convertCurrencyStringToNumber(receipt.items[i].total_price);
        calculatedSum = calculatedSum + totalPrice;
    }
    if (checkSum) {
        calculatedSum = Math.round(calculatedSum * 100) / 100;
        if (calculatedSum !== parseFloat(receipt.sum.replace(",","."))) {
            let calculatedSumString = convertNumberToCurrencyString(calculatedSum);
            receiptFeedback.feedbacks.push({fieldName: "sum", severity: "error", message: t("receipts.feedback.wrongSum", {correctSum: calculatedSumString})});
        }
    }
    // item total price
    for (let i = 0; i < receipt.items.length; i++) {
        let item = receipt.items[i];
        let itemFeedback = receiptFeedback.items[i];
        let checkTotalPrice = itemFeedback.feedbacks.find(ff => ff.fieldName === "total_price") === undefined;
        if (isEmpty(item.amount) && isEmpty(item.unit_price) && isEmpty(item.weight) && isEmpty(item.price_per_si_unit)) {
            checkTotalPrice = false;
        } else if (!isEmpty(item.amount) || !isEmpty(item.unit_price)) {
            if (itemFeedback.feedbacks.find(ff => ff.fieldName === "amount") !== undefined) {
                checkTotalPrice = false;
            } else if (itemFeedback.feedbacks.find(ff => ff.fieldName === "unit_price") !== undefined) {
                checkTotalPrice = false;
            }
        } else if (!isEmpty(item.weight) || !isEmpty(item.price_per_si_unit)) {
            if (itemFeedback.feedbacks.find(ff => ff.fieldName === "weight") !== undefined) {
                checkTotalPrice = false;
            } else if (itemFeedback.feedbacks.find(ff => ff.fieldName === "price_per_si_unit") !== undefined) {
                checkTotalPrice = false;
            }
        }
        if (checkTotalPrice) {
            if (!isEmpty(item.amount)) {
                let amount = convertStringToInteger(item.amount);
                let unitPrice = convertCurrencyStringToNumber(item.unit_price);
                let calculatedTotalPrice = amount * unitPrice;
                calculatedTotalPrice = Math.round(calculatedTotalPrice * 100) / 100;
                if (calculatedTotalPrice !== convertCurrencyStringToNumber(item.total_price)) {
                    itemFeedback.feedbacks.push({fieldName: "total_price", severity: "error", message: t("receipts.feedback.wrongTotalPrice", {correctTotalPrice: convertNumberToCurrencyString(calculatedTotalPrice)})});
                }
            } else {
                let weight = convertStringToNumber(item.weight);
                let pricePerSiUnit = convertCurrencyStringToNumber(item.price_per_si_unit);
                let calculatedTotalPrice = weight * pricePerSiUnit;
                calculatedTotalPrice = Math.round(calculatedTotalPrice * 100) / 100;
                if (calculatedTotalPrice !== convertCurrencyStringToNumber(item.total_price)) {
                    itemFeedback.feedbacks.push({fieldName: "total_price", severity: "error", message: t("receipts.feedback.wrongTotalPrice", {correctTotalPrice: convertNumberToCurrencyString(calculatedTotalPrice)})});
                }
            }
        }
    }
    // database inconsistency
    let storeDatabase = null;
    if (receiptFeedback.feedbacks.find(ff => ff.fieldName === "store_name") === undefined) {
        storeDatabase = database.find(sd => sd.store === receipt.store.name);
        if (storeDatabase === undefined) {
            storeDatabase = null;
            receiptFeedback.feedbacks.push({fieldName: "store_name", severity: "warning", message: t("receipts.feedback.unknownStoreName")});
        }
    }
    if (storeDatabase !== null) {
        for (let i = 0; i < receipt.items.length; i++) {
            let item = receipt.items[i];
            let itemFeedback = receiptFeedback.items[i];
            let databaseItem = null;
            if (itemFeedback.feedbacks.find(ff => ff.fieldName === "name") === undefined) {
                databaseItem = storeDatabase.items.find(di => di.name === item.name);
                if (databaseItem === undefined) {
                    databaseItem = null;
                    itemFeedback.feedbacks.push({fieldName: "name", severity: "warning", message: t("receipts.feedback.unknownProductName")})
                }
            }
            if (databaseItem !== null) {
                if (itemFeedback.feedbacks.find(ff => ff.fieldName === "number") === undefined) {
                    let number = isEmpty(item.number) ? null : convertStringToInteger(item.number);
                    if (number !== databaseItem.number) {
                        let text;
                        if (databaseItem.number !== null) {
                            text = t("receipts.feedback.numberNotEmpty", {correctNumber: databaseItem.number});
                        } else {
                            text = t("receipts.feedback.numberEmpty");
                        }
                        itemFeedback.feedbacks.push({fieldName: "number", severity: "warning", message: t("receipts.feedback.wrongNumber") + " " + text});
                    }
                }
                let checkPricePerOne = true;
                if (isEmpty(item.amount) && isEmpty(item.unit_price) && isEmpty(item.weight) && isEmpty(item.price_per_si_unit)) {
                    if (itemFeedback.feedbacks.find(ff => ff.fieldName === "total_price") !== undefined) {
                        checkPricePerOne = false;
                    }
                } else {
                    if (!isEmpty(item.amount) || !isEmpty(item.unit_price)) {
                        if (itemFeedback.feedbacks.find(ff => ff.fieldName === "amount") !== undefined) {
                            checkPricePerOne = false;
                        } else if (itemFeedback.feedbacks.find(ff => ff.fieldName === "unit_price") !== undefined) {
                            checkPricePerOne = false;
                        }
                    } else {
                        if (itemFeedback.feedbacks.find(ff => ff.fieldName === "weight") !== undefined) {
                            checkPricePerOne = false;
                        } else if (itemFeedback.feedbacks.find(ff => ff.fieldName === "price_per_si_unit") !== undefined) {
                            checkPricePerOne = false;
                        }
                    }
                }
                if (checkPricePerOne && receipt.date !== null) {
                    let pricePerOne = convertCurrencyStringToNumber(getPricePerOne(item));
                    let possibleDatabasePrices = getMostFittingPrices(databaseItem.prices, receipt.date);
                    if (possibleDatabasePrices.find(tp => tp.price === pricePerOne) === undefined) {
                        let possiblePrices = possibleDatabasePrices.map(tp => tp.price);
                        let distinctPrices = possiblePrices.filter((p, index) => possiblePrices.indexOf(p) === index);
                        let priceShouldBe = "";
                        for (let j = 0; j < distinctPrices.length; j++) {
                            let price = distinctPrices[j];
                            if (j < distinctPrices.length - 2) {
                                priceShouldBe = priceShouldBe + convertNumberToCurrencyString(price) + ", ";
                            } else if (j === distinctPrices.length - 2) {
                                priceShouldBe = priceShouldBe + convertNumberToCurrencyString(price) + " " + t("common.or") + " ";
                            } else {
                                priceShouldBe = priceShouldBe + convertNumberToCurrencyString(price);
                            }
                        }
                        if (!isEmpty(item.unit_price)) {
                            itemFeedback.feedbacks.push({fieldName: "unit_price", severity: "warning", message: t("receipts.feedback.unknownUnitPrice", {correctPrices: priceShouldBe})})
                        } else if (!isEmpty(item.price_per_si_unit)) {
                            itemFeedback.feedbacks.push({fieldName: "price_per_si_unit", severity: "warning", message: t("receipts.feedback.unknownPricePerSiUnit", {correctPrices: priceShouldBe})})
                        } else {
                            itemFeedback.feedbacks.push({fieldName: "total_price", severity: "warning", message: t("receipts.feedback.unknownTotalPrice", {correctPrices: priceShouldBe})})
                        }
                    }
                }
            }
        }
    }
    receiptFeedback.items = receiptFeedback.items.filter(itfe => itfe.feedbacks.length > 0);
    receiptFeedback.items = receiptFeedback.items.length > 0 ? receiptFeedback.items : null;
    receiptFeedback.feedbacks = receiptFeedback.feedbacks.length > 0 ? receiptFeedback.feedbacks : null;
    return receiptFeedback.items !== null || receiptFeedback.feedbacks !== null ? receiptFeedback : null;
}

/**
 * Checks whether there is already feedback for the specified field name in the feedback container.
 * If there is no feedback, it checks whether the value fulfils the condition.
 * If the condition returns true for the value, it adds the new feedback to the feedback container.
 *
 * @param value The value that should be checked.
 * @param {function} condition The condition the value must fulfil, if the new feedback should be added to the container.
 * @param {ReceiptFeedback|ItemFeedback} feedbackContainer The container that should collect the new feedback
 * @param {FieldFeedback} newFeedback The new feedback that should be added to the container.
 */
function check(value, condition, feedbackContainer, newFeedback) {
    if (feedbackContainer.feedbacks.find(ff => ff.fieldName === newFeedback.fieldName) !== undefined) {
        return;
    }
    if (condition(value)) {
        feedbackContainer.feedbacks.push(newFeedback);
    }
}

/**
 * Checks whether there are already feedbacks for each specified field name in the feedback container.
 * If there is one field name without a feedback, it checks whether the condition is fulfilled by the values.
 * If the condition returns true for the values, it adds each new feedback to the feedback container,
 * if there is not already a feedback with the same field name.
 *
 * @param {Array} values The value that should be checked.
 * @param {function} condition The condition the value must fulfil, if the new feedback should be added to the container.
 * @param {ReceiptFeedback|ItemFeedback} feedbackContainer The container that should collect the new feedback
 * @param {FieldFeedback[]} newFeedbacks The new feedback that should be added to the container.
 */
function checkMultiple(values, condition, feedbackContainer, newFeedbacks) {
    if (newFeedbacks.every(fe => feedbackContainer.feedbacks.find(ff => ff.fieldName === fe.fieldName) !== undefined)) {
        return;
    }
    if (condition(values)) {
        for (let i = 0; i < newFeedbacks.length; i++) {
            if (feedbackContainer.feedbacks.find(ff => ff.fieldName === newFeedbacks[i].fieldName) === undefined) {
                feedbackContainer.feedbacks.push(newFeedbacks[i]);
            }
        }
    }
}

function isEmpty(value) {
    return value === "";
}

function isInteger(value) {
    let regExp = /^\d+$/g
    return value.match(regExp) !== null;
}

function isNumber(value) {
    let regExp = /^\d+(,\d+)?$/g;
    return value.match(regExp) !== null;
}

function isCurrency(value) {
    let regExp = /^-?\d+,\d{2}$/g;
    return value.match(regExp) !== null;
}

function not(otherFunction) {
    return v => !otherFunction(v);
}