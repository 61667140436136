import React from 'react';
import {Button} from "primereact/button";

import {withTranslation} from "react-i18next";

class DatabaseUpload extends React.Component {
    constructor(props) {
        super(props);

        this.input = null;
    }

    renderValidState() {
        const t = this.props.t;
        if (this.props.loadDatabaseFile) {
            return (
                <div className="mt-2 flex justify-content-center align-items-center gap-2">
                    <i className="pi pi-spin pi-spinner text-primary" style={{'fontSize': '1em'}}/>
                    <div className="text-primary">
                        {t("dataAdministration.loadInput")}
                    </div>
                </div>
            );
        } else if (!this.props.usedDatabaseFile) {
            return (<></>);
        } else if (this.props.validDatabaseFile) {
            return (
                <div className="mt-2 flex justify-content-center align-items-center gap-2">
                    <i className="pi pi-check text-green-800" style={{'fontSize': '1em'}}/>
                    <div className="text-green-800">
                        {t("dataAdministration.validInput")}
                    </div>
                </div>
            );
        } else {
            return (
                <div className="mt-2 flex justify-content-center align-items-center gap-2">
                    <i className="pi pi-exclamation-circle text-red-800" style={{'fontSize': '1em'}}/>
                    <div className="text-red-800">
                        {t("dataAdministration.invalidInput")}
                    </div>
                </div>
            );
        }
    }

    renderDatabase() {
        const t = this.props.t;
        if (this.props.databaseFile !== null) {
            return (
                <div className="block surface-500 p-3 border-round cursor-pointer" onClick={() => this.input.click()}>
                    <div className="flex justify-content-between gap-3">
                        <div className="font-bold">
                            {t("dataAdministration.fileName")}
                        </div>
                        <div className="text-right">
                            {this.props.databaseFile.name}
                        </div>
                    </div>
                    <div className="flex justify-content-between gap-3">
                        <div className="font-bold">
                            {t("dataAdministration.fileSize")}
                        </div>
                        <div className="text-right">
                            {this.props.databaseFile.size + " B"}
                        </div>
                    </div>
                    <div className="flex justify-content-between gap-3">
                        <div className="font-bold">
                            {t("dataAdministration.lastModified")}
                        </div>
                        <div className="text-right">
                            {this.formatDate(new Date(this.props.databaseFile.lastModified))}
                        </div>
                    </div>
                    {this.renderValidState()}
                </div>
            );
        } else {
            return (
                <div className="flex flex-column align-items-center justify-content-center surface-500 px-7 py-2 border-round">
                    <i className="pi pi-file text-white" style={{'fontSize': '4em'}}/>
                    {this.renderValidState()}
                </div>
            );
        }
    }

    renderButton() {
        const t = this.props.t;
        if (this.props.databaseFile === null) {
            return (
                <Button label={t("dataAdministration.chooseFile")} icon="pi pi-plus" onClick={() => this.input.click()}/>
            );
        } else {
            return (
                <span className="p-buttonset">
                    <Button label={t("dataAdministration.replaceFile")} icon="pi pi-plus" onClick={() => this.input.click()}/>
                    <Button label={t("dataAdministration.removeFile")} icon="pi pi-trash" onClick={() => this.removeDatabase()}/>
                </span>
            );
        }
    }

    render() {
        return (
            <>
                {this.renderDatabase()}
                {this.renderButton()}
                <input className="hidden" type="file" accept="application/json" ref={(el) => this.input = el} onChange={(e) => this.onDatabaseSelect()}/>
            </>
        );
    }

    removeDatabase() {
        this.input.value = '';
        this.onDatabaseSelect();
    }

    onDatabaseSelect() {
        if (this.input.files.length === 1) {
            this.props.onDatabaseChange(this.input.files[0]);
        } else {
            this.props.onDatabaseChange(null);
        }
    }

    formatDate(date) {
        let dateString = (date.getDate() < 10 ? "0" : "") + date.getDate();
        let monthString = (date.getMonth() + 1 < 10 ? "0" : "") + (date.getMonth() + 1);
        let yearString = "" + date.getFullYear();
        let hourString = (date.getHours() < 10 ? "0" : "") + date.getHours();
        let minuteString = (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();

        return dateString
            + "." + monthString
            + "." + yearString
            + " " + hourString
            + ":" + minuteString;
    }
}

export default withTranslation()(DatabaseUpload);