class PageType {
    static START = new PageType("START");
    static RECEIPT = new PageType("RECEIPT");
    static STATISTIC = new PageType("STATISTIC");
    static DATA_ADMINISTRATION = new PageType("DATA_ADMINISTRATION");

    constructor(name) {
        this.name = name;
    }
}

export default PageType;