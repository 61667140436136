import React from 'react';
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {getDayOfWeek, getNameOfDayOfWeek, getCalenderWeek, getNameOfMonth, formatDate} from "./DateUtil";
import TimeGroupingType from "./TimeGroupingType";

import {withTranslation} from "react-i18next";

class AggregateTable extends React.Component {

    //stores
    //categories
    //timeGroups

    getDimensions() {
        let dimensions = 0;
        dimensions += this.props.stores !== null ? 1 : 0;
        dimensions += this.props.categories !== null ? 1 : 0;
        dimensions += this.props.timeGroups !== null ? 1 : 0;
        return dimensions;
    }

    getColumnType() {
        let dimensions = this.getDimensions();
        if (this.props.timeGroups !== null) {
            return dimensions > 1 ? "timeGroups" : null;
        } else if (this.props.stores !== null) {
            return dimensions > 1 ? "stores" : null;
        } else {
            return null;
        }
    }

    getOuterRowType() {
        if (this.props.timeGroups !== null) {
            if (this.props.stores !== null) {
                return "stores";
            } else if (this.props.categories !== null) {
                return "categories";
            } else {
                return "timeGroups";
            }
        } else if (this.props.stores !== null) {
            if (this.props.categories !== null) {
                return "categories";
            } else {
                return "stores";
            }
        } else if (this.props.categories !== null) {
            return "categories"
        } else {
            return null;
        }
    }

    getInnerRowType() {
        return this.getDimensions() === 3 ? "categories" : null;
    }

    formatPrice(price) {
        let priceString = price + "";
        let indexOfPoint = priceString.lastIndexOf(".");
        let indexOfEnd = priceString.length - indexOfPoint - 1;
        if (indexOfPoint === -1) {
            priceString = priceString + ",00€";
        } else if (indexOfEnd === 1) {
            priceString = priceString.replaceAll(".", ",") + "0€";
        } else if (indexOfEnd === 2) {
            priceString = priceString.replaceAll(".", ",") + "€";
        } else {
            priceString = priceString.replaceAll(".", ",").substring(0, indexOfPoint + 2) + "€";
        }
        return priceString;
    }

    getTimeGroupRepresentation(timeGroup) {
        const t = this.props.t;
        if (this.props.groupTime === TimeGroupingType.DAY) {
            let weekday = getNameOfDayOfWeek(getDayOfWeek(timeGroup[0]), t);
            return weekday + ", " + formatDate(timeGroup[0]);
        }
        if (this.props.groupTime === TimeGroupingType.WEEK) {
            let weekNumber = getCalenderWeek(timeGroup[0]);
            let end = new Date(timeGroup[1].getTime() - 1);
            return t("statistics.weekAbbreviation") + " " + weekNumber + " (" + formatDate(timeGroup[0]) + "-" + formatDate(end) + ")";
        }
        if (this.props.groupTime === TimeGroupingType.MONTH) {
            let month = getNameOfMonth(timeGroup[0].getMonth(), t);
            let end = new Date(timeGroup[1].getTime() - 1);
            return month + " (" + formatDate(timeGroup[0]) + "-" + formatDate(end) + ")";
        }
    }

    expensesTemplate(rowData) {
        return (
            <>
                {this.formatPrice(rowData.expenses)}
            </>
        );
    }

    renderExpensesColumn() {
        const t = this.props.t;
        return (
            <Column key="e" header={t("statistics.expenses")} body={rowData => this.expensesTemplate(rowData)}/>
        );
    }

    timeGroupTemplate(rowData) {
        return (
            <>
                <b>{this.getTimeGroupRepresentation(rowData.timeGroup)}</b>
            </>
        );
    }

    renderTimeGroupColumn() {
        const t = this.props.t;
        return (
            <Column key="tg" header={t("statistics.timePeriod")} body={rowData => this.timeGroupTemplate(rowData)}/>
        );
    }

    storeTemplate(rowData) {
        return (
            <>
                <b>{rowData.store}</b>
            </>
        );
    }

    renderStoreColumn() {
        const t = this.props.t;
        return (
            <Column key="s" field="store" header={t("statistics.store")} body={rowData => this.storeTemplate(rowData)}/>
        );
    }

    categoryTemplate(rowData) {
        return (
            <>
                <b>{rowData.category}</b>
            </>
        );
    }

    renderCategoryColumn() {
        const t = this.props.t;
        return (
            <Column key="c" header={t("statistics.category")} body={rowData => this.categoryTemplate(rowData)}/>
        )
    }

    sumTemplate(rowData) {
        return (
            <>
                <b>{this.formatPrice(rowData.sum)}</b>
            </>
        );
    }

    renderSumColumn() {
        return (
            <Column key="su" header="" body={rowData => this.sumTemplate(rowData)}/>
        );
    }

    timeGroupsTemplate(rowData, index) {
        return (
            <>
                {this.formatPrice(rowData.timeGroups[index])}
            </>
        );
    }

    renderTimeGroupColumns(columnSums) {
        return this.props.timeGroups.map((value, index) => {
            return (
                <Column key={"tg" + index} header={this.getTimeGroupRepresentation(value)}
                        body={rowData => this.timeGroupsTemplate(rowData, index)}
                        footer={this.formatPrice(columnSums[index])}/>
            );
        });
    }

    storesTemplate(rowData, index) {
        return (
            <>
                {this.formatPrice(rowData.stores[index])}
            </>
        );
    }

    renderStoreColumns(columnSums) {
        return this.props.stores.map((value, index) => <Column key={"s" + index} header={value}
                                                               body={rowData => this.storesTemplate(rowData, index)}
                                                               footer={this.formatPrice(columnSums[index])}/>);
    }

    renderColumnType(type) {
        if (type === "timeGroups") {
            return this.renderTimeGroupColumn();
        } else if (type === "stores") {
            return this.renderStoreColumn();
        } else if (type === "categories") {
            return this.renderCategoryColumn();
        } else {
            return null;
        }
    }

    renderColumnsType(type, columnSums) {
        if (type === "timeGroups") {
            return this.renderTimeGroupColumns(columnSums);
        } else if (type === "stores") {
            return this.renderStoreColumns(columnSums);
        } else {
            return null;
        }
    }

    renderColumns(columnSums) {
        let dimensions = this.getDimensions();
        let columnType = this.getColumnType();
        let outerRowType = this.getOuterRowType();
        let innerRowType = this.getInnerRowType();

        if (dimensions === 0) {
            return this.renderExpensesColumn();
        } else if (dimensions === 1) {
            return [this.renderColumnType(outerRowType), this.renderExpensesColumn()];
        } else if (dimensions === 2) {
            return [this.renderColumnType(outerRowType), this.renderColumnsType(columnType, columnSums), this.renderSumColumn()];
        } else {
            return [this.renderColumnType(outerRowType), this.renderColumnType(innerRowType), this.renderColumnsType(columnType, columnSums), this.renderSumColumn()];
        }
    }

    getDataLength(data, type) {
        if (type === "timeGroups") {
            return data[0][0].length;
        } else if (type === "stores") {
            return data.length;
        } else if (type === "categories") {
            return data[0].length;
        } else {
            throw new Error("Illegal type value. Received: " + type);
        }
    }

    getDataValue(data, type, firstIndex, secondIndex, iterationIndex) {
        if (type === "timeGroups") {
            return data[firstIndex][secondIndex][iterationIndex];
        } else if (type === "stores") {
            return data[iterationIndex][firstIndex][secondIndex];
        } else if (type === "categories") {
            return data[firstIndex][iterationIndex][secondIndex];
        } else {
            throw new Error("Illegal type value. Received: " + type);
        }
    }

    addColumnsToRowData(rowData, data, type, firstIndex, secondIndex) {
        let values = new Array(this.getDataLength(data, type));
        for (let i = 0; i < values.length; i++) {
            values[i] = this.getDataValue(data, type, firstIndex, secondIndex, i);
        }
        rowData[type] = values;
    }

    formatData(data) {
        if (data === null) {
            return [];
        }
        let dimensions = this.getDimensions();
        let columnType = this.getColumnType();
        let outerRowType = this.getOuterRowType();
        let innerRowType = this.getInnerRowType();

        if (dimensions === 0) {
            return [{
                expenses: data[0][0][0]
            }];
        } else if (dimensions === 1) {
            if (outerRowType === "timeGroups") {
                return this.props.timeGroups.map((value, index) => {
                    return {
                        timeGroup: value,
                        expenses: data[0][0][index]
                    };
                });
            } else if (outerRowType === "stores") {
                return this.props.stores.map((value, index) => {
                    return {
                        store: value,
                        expenses: data[index][0][0]
                    };
                });
            } else if (outerRowType === "categories") {
                return this.props.categories.map((value, index) => {
                    return {
                        category: value,
                        expenses: data[0][index][0]
                    };
                });
            }
        } else if (dimensions === 2) {
            if (outerRowType === "stores") {
                let sums = this.getOuterRowSums(data, "stores");
                return this.props.stores.map((value, index) => {
                    let rowData = {
                        store: value,
                        sum: sums[index]
                    };
                    this.addColumnsToRowData(rowData, data, "timeGroups", index, 0);
                    return rowData;
                });
            } else if (outerRowType === "categories") {
                let sums = this.getOuterRowSums(data, "categories");
                if (columnType === "timeGroups") {
                    return this.props.categories.map((value, index) => {
                        let rowData = {
                            category: value,
                            sum: sums[index]
                        };
                        this.addColumnsToRowData(rowData, data, "timeGroups", 0, index);
                        return rowData;
                    });
                } else {
                    return this.props.categories.map((value, index) => {
                        let rowData = {
                            category: value,
                            sum: sums[index]
                        };
                        this.addColumnsToRowData(rowData, data, "stores", index, 0);
                        return rowData;
                    });
                }
            }
        } else {
            let sums = this.getInnerRowSums(data, "stores", "categories");
            let rowDatas = [];
            for (let i = 0; i < this.props.stores.length; i++) {
                let store = this.props.stores[i];
                rowDatas = rowDatas.concat(this.props.categories.map((value, index) => {
                    let rowData = {
                        store: store,
                        category: value,
                        sum: sums[i][index]
                    };
                    this.addColumnsToRowData(rowData, data, "timeGroups", i, index);
                    return rowData;
                }));
            }
            return rowDatas;

        }
    }

    getColumnSums(data, columnType) {
        if (columnType === "timeGroups") {
            let sums = new Array(data[0][0].length);
            for (let i = 0; i < sums.length; i++) {
                let sum = 0;
                for (let j = 0; j < data.length; j++) {
                    for (let k = 0; k < data[0].length; k++) {
                        sum += data[j][k][i];
                    }
                }
                sums[i] = this.roundToTwoDigits(sum);
            }
            return sums;
        } else if (columnType === "stores") {
            let sums = new Array(data.length);
            for (let i = 0; i < sums.length; i++) {
                let sum = 0;
                for (let j = 0; j < data[0].length; j++) {
                    sum += data[i][j][0];
                }
                sums[i] = this.roundToTwoDigits(sum);
            }
            return sums;
        }
    }

    //only for dimensions === 2
    getOuterRowSums(data, rowType) {
        if (rowType === "stores") {
            let sums = new Array(data.length);
            for (let i = 0; i < sums.length; i++) {
                let sum = 0;
                for (let j = 0; j < data[i][0].length; j++) {
                    sum += data[i][0][j];
                }
                sums[i] = this.roundToTwoDigits(sum);
            }
            return sums;
        } else if (rowType === "categories") {
            let sums = new Array(data[0].length);
            for (let i = 0; i < sums.length; i++) {
                let sum = 0;
                for (let j = 0; j < data.length; j++) {
                    for (let k = 0; k < data[0][0].length; k++) {
                        sum += data[j][i][k];
                    }
                }
                sums[i] = this.roundToTwoDigits(sum);
            }
            return sums;
        }
    }

    //only for dimensions === 3
    getInnerRowSums(data, outerRowType, innerRowType) {
        if (outerRowType === "stores" && innerRowType === "categories") {
            let sums = new Array(data.length);
            for (let i = 0; i < sums.length; i++) {
                sums[i] = new Array(data[0].length);
            }
            for (let i = 0; i < sums.length; i++) {
                for (let j = 0; j < sums[0].length; j++) {
                    let sum = 0;
                    for (let k = 0; k < data[i][j].length; k++) {
                        sum += data[i][j][k];
                    }
                    sums[i][j] = this.roundToTwoDigits(sum);
                }
            }
            return sums;
        }
    }

    roundToTwoDigits(number) {
        return Math.round(number * 100) / 100;
    }

    render() {
        let dimensions = this.getDimensions();
        let columnSums = null;
        if (dimensions >= 2) {
            columnSums = this.getColumnSums(this.props.data, this.getColumnType());
        }

        let columns = this.renderColumns(columnSums);
        return (
            <>
                <DataTable value={this.formatData(this.props.data)} loading={this.props.loading}
                           responsiveLayout="scroll" sortField={dimensions === 3 ? "store" : null}
                           rowGroupMode={dimensions === 3 ? "rowspan" : null}
                           groupRowsBy={dimensions === 3 ? "store" : null}>
                    {columns}
                </DataTable>
            </>
        );
    }
}

export default withTranslation()(AggregateTable);