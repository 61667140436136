import React from 'react';
import {Divider} from "primereact/divider";
import ProductEditDialog from "./ProductEditDialog";
import PropTypes from "prop-types";
import {getFieldFeedbacks} from "../../data/FeedbackUtil";

import {withTranslation} from "react-i18next";


class ProductCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            editDialogOpen: false
        }
    }

    getActionColor(severity) {
        if (severity === "info") {
            return "cyan-200";
        } else if (severity === "warning") {
            return "orange-200";
        } else if (severity === "error") {
            return "red-200";
        }
        throw new Error("Illegal argument severity: " + severity);
    }

    getBackgroundColor(severity) {
        if (severity === "info") {
            return "cyan-100";
        } else if (severity === "warning") {
            return "orange-100";
        } else if (severity === "error") {
            return "red-100";
        }
        throw new Error("Illegal argument severity: " + severity);
    }

    getTextColor(severity) {
        if (severity === "info") {
            return "cyan-500";
        } else if (severity === "warning") {
            return "orange-500";
        } else if (severity === "error") {
            return "red-500";
        }
        throw new Error("Illegal argument severity: " + severity);
    }

    getIcon(severity) {
        if (severity === "info") {
            return "pi-info-circle";
        } else if (severity === "warning") {
            return "pi-exclamation-triangle";
        } else if (severity === "error") {
            return "pi-exclamation-circle";
        }
        throw new Error("Illegal argument severity: " + severity);
    }

    renderNumber() {
        if (this.props.item.number === "") {
            return (<></>);
        }
        return (
            <div className="text-xl">
                {this.props.item.number}
            </div>
        );
    }

    renderName() {
        return (
            <div className="text-xl font-semibold">
                {this.props.item.name !== "" ? this.props.item.name : "???"}
            </div>
        );
    }

    renderFeedback(feedbackSummary) {
        const t = this.props.t;
        if (feedbackSummary === null) {
            return (<div/>);
        }
        return (
            <div className="flex align-items-center justify-content-left gap-2">
                <i className={"pi " + this.getIcon(feedbackSummary.severity) + " text-" + this.getTextColor(feedbackSummary.severity)}/>
                <span className={"text-" + this.getTextColor(feedbackSummary.severity)}>
                    {t("receipts." + feedbackSummary.severity + "Hint", {count: feedbackSummary.count})}
                </span>
            </div>
        );
    }

    summarizeFeedback() {
        if (this.props.itemFeedback === undefined || this.props.itemFeedback === null || this.props.itemFeedback.feedbacks === null) {
            return null;
        }
        let infos = this.props.itemFeedback.feedbacks.filter(fe => fe.severity === "info").length;
        let warnings = this.props.itemFeedback.feedbacks.filter(fe => fe.severity === "warning").length;
        let errors = this.props.itemFeedback.feedbacks.filter(fe => fe.severity === "error").length;

        if (errors > 0) {
            return {
                severity: "error",
                count: errors
            }
        }
        if (warnings > 0) {
            return {
                severity: "warning",
                count: warnings
            }
        }
        if (infos > 0) {
            return {
                severity: "info",
                count: infos
            }
        }
        return null;
    }

    renderTotalPrice() {
        return (
            <div className="text-right text-lg font-medium">
                {this.props.item.total_price !== "" ? this.props.item.total_price + " €" : "??,?? €"}
            </div>
        );
    }

    existsAdditionalInformation() {
        let item = this.props.item;
        return item.category !== ""
            || item.tax_category !== ""
            || item.amount !== ""
            || item.unit_price !== ""
            || item.weight !== ""
            || item.si_unit !== ""
            || item.price_per_si_unit !== "";
    }

    existsLeftAdditionalInformation() {
        let item = this.props.item;
        return item.category !== "" || item.tax_category !== "";
    }

    existsRightAdditionalInformation() {
        let item = this.props.item;
        return item.amount !== ""
            || item.unit_price !== ""
            || item.weight !== ""
            || item.si_unit !== ""
            || item.price_per_si_unit !== "";
    }

    existsAmountInformation() {
        let item = this.props.item;
        return item.amount !== "" || item.unit_price !== "";
    }

    renderAdditionalInformation() {
        if (!this.existsAdditionalInformation()) {
            return (<></>);
        }
        if (this.existsLeftAdditionalInformation() && this.existsRightAdditionalInformation()) {
            return (
                <div className="flex justify-content-between">
                    {this.renderLeftAdditionalInformation()}
                    <Divider layout="vertical"/>
                    {this.renderRightAdditionalInformation()}
                </div>
            );
        } else if (this.existsLeftAdditionalInformation()) {
            return (
                <div className="flex justify-content-start">
                    {this.renderLeftAdditionalInformation()}
                </div>
            )
        } else {
            return (
                <div className="flex justify-content-end">
                    {this.renderRightAdditionalInformation()}
                </div>
            );
        }
    }

    renderLeftAdditionalInformation() {
        return (
            <div className="flex flex-column align-items-start justify-content-center">
                {this.renderCategory()}
                {this.renderTaxCategory()}
            </div>
        );
    }

    renderCategory() {
        if (this.props.item.category === "") {
            return (<></>);
        }
        return (
            <div>{this.props.item.category}</div>
        )
    }

    renderTaxCategory() {
        const t = this.props.t;
        if (this.props.item.tax_category === "") {
            return (<></>);
        }
        return (
            <div>{t("receipts.taxLabel") + " " + this.props.item.tax_category}</div>
        )
    }

    renderRightAdditionalInformation() {
        return (
            <div className="flex flex-column align-items-end justify-content-center">
                {this.existsAmountInformation() ? this.renderAmount() : this.renderWeight()}
            </div>
        );
    }

    renderAmount() {
        const t = this.props.t;
        return (
            <>
                <div className="text-right">
                    {(this.props.item.amount !== "" ? this.props.item.amount : "???") + " " + t("receipts.piecesAbbreviation") + " x"}
                </div>
                <div className="text-right">
                    {(this.props.item.unit_price !== "" ? this.props.item.unit_price : "??,??") + " € / " + t("receipts.piecesAbbreviation")}
                </div>
            </>
        );
    }

    renderWeight() {
        return (
            <>
                <div className="text-right">
                    {(this.props.item.weight !== "" ? this.props.item.weight : "??,???") + " " + (this.props.item.si_unit !== "" ? this.props.item.si_unit : "???") + " x"}
                </div>
                <div className="text-right">
                    {(this.props.item.price_per_si_unit !== "" ? this.props.item.price_per_si_unit : "??,???") + " € / " + (this.props.item.si_unit !== "" ? this.props.item.si_unit : "???")}
                </div>
            </>
        );
    }

    renderOverlay() {
        if (this.props.addProductMode) {
            return (
                <div className="absolute bottom-0 top-0 left-0 right-0 surface-700 opacity-50 border-round flex justify-content-center align-items-center">
                    <i className="pi pi-arrow-circle-up text-white" style={{'fontSize': '3em'}}/>
                </div>
            );
        } else {
            return <></>;
        }
    }

    render() {
        let feedbackSummary = this.summarizeFeedback();
        let backgroundColor = "surface-100 active:surface-200";
        if (feedbackSummary !== null) {
            backgroundColor = "bg-" + this.getBackgroundColor(feedbackSummary.severity)
                + " active:bg-" + this.getActionColor(feedbackSummary.severity);
        }
        return (<>
            <div className={"relative shadow-2 border-round mt-1 p-3 p-component flex flex-column gap-2 " + backgroundColor}
                 onClick={this.props.addProductMode ? () => this.props.onAdd() : () => this.setState({editDialogOpen: true})}>
                {this.renderOverlay()}
                <div className="flex justify-content-start gap-2">
                    {this.renderNumber()}
                    {this.renderName()}
                </div>
                {this.renderAdditionalInformation()}
                <div className="flex justify-content-between">
                    {this.renderFeedback(feedbackSummary)}
                    {this.renderTotalPrice()}
                </div>
            </div>
            <ProductEditDialog visible={this.state.editDialogOpen} onHide={() => this.setState({editDialogOpen: false})}
                               item={this.props.item}
                               onChange={(fieldName, newValue) => this.props.onChange(this.props.item.id, fieldName, newValue)}
                               onDelete={() => this.props.onDelete(this.props.item.id)}
                               fieldFeedbacks={getFieldFeedbacks(this.props.itemFeedback)}/>
        </>);
    }
}

ProductCard.propTypes = {
    addProductMode: PropTypes.bool.isRequired,
    item: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        number: PropTypes.string.isRequired,
        amount: PropTypes.string.isRequired,
        unit_price: PropTypes.string.isRequired,
        weight: PropTypes.string.isRequired,
        si_unit: PropTypes.string.isRequired,
        price_per_si_unit: PropTypes.string.isRequired,
        total_price: PropTypes.string.isRequired,
        tax_category: PropTypes.string.isRequired,
        category: PropTypes.string.isRequired
    }).isRequired,
    itemFeedback: PropTypes.shape({
        itemId: PropTypes.number.isRequired,
        feedbacks: PropTypes.arrayOf(PropTypes.shape({
            fieldName: PropTypes.string.isRequired,
            severity: PropTypes.oneOf(["info", "warning", "error"]).isRequired,
            message: PropTypes.string.isRequired
        }).isRequired)
    }),
    onChange: PropTypes.func.isRequired,
    onAdd: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired
};

export default withTranslation()(ProductCard);