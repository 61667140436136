class TimeGroupingType {
    static MONTH = new TimeGroupingType("MONTH");
    static WEEK = new TimeGroupingType("WEEK");
    static DAY = new TimeGroupingType("DAY");

    constructor(name) {
        this.name = name;
    }
}

export default TimeGroupingType;