import databaseSchema from "./DatabaseSchema.json";
import Ajv2020 from "ajv/dist/2020";

const ajv = new Ajv2020();
const validate = ajv.compile(databaseSchema);

/**
 * Read the input data from the local storage.
 *
 * @returns {LoadInputDataResult}
 */
export function readLocalStorage() {
    let storageString;
    try {
        storageString = localStorage.getItem("data");
    } catch (e) {
        // catch SecurityError (is thrown, if the user disabled the localStorage of the browser)
        console.log(e);
        return {success: false, reason: "security"};
    }
    if (storageString === null) {
        return {success: true, foundData: false};
    }
    return readString(storageString);
}

/**
 * Read the input data from a file.
 *
 * @param {File} file
 * @returns {Promise<LoadInputDataResult>}
 */
export async function readFile(file) {
    let fileString;
    try {
        fileString = await file.text();
    } catch (error) {
        console.log(error);
        return {success: false, reason: "io"};
    }
    return readString(fileString);
}

/**
 * Read the input data from a string.
 *
 * @param {string} string
 * @returns {LoadInputDataResult}
 */
function readString(string) {
    let storageJson;
    try {
        storageJson = JSON.parse(string);
    } catch (e) {
        console.log(e);
        return {success: false, reason: "syntax"};
    }
    let valid = validateJson(storageJson);
    if (!valid) {
        return {success: false, reason: "schema"};
    }
    return {success: true, foundData: true, receipts: storageJson.receipts, store_database: storageJson.store_database};

}




export function writeLocalStorage(savedReceipts, savedDatabase) {
    let storageJson = {receipts: savedReceipts, store_database: savedDatabase};
    let storageString = JSON.stringify(storageJson);
    try {
        localStorage.setItem("data", storageString);
    } catch (e) {
        console.log(e);
        return {success: false, reason: "security"};
    }
    return {success: true};
}

export function savedReceiptsWithoutIds(savedReceipts) {
    let downloadReceipts = [];
    for (let i = 0; i < savedReceipts.length; i++) {
        let receipt = savedReceipts[i];
        let downloadReceipt = Object.assign({}, receipt);
        delete downloadReceipt.id;
        let downloadItems = [];
        for (let j = 0; j < downloadReceipt.items.length; j++) {
            let item = downloadReceipt.items[j];
            let downloadItem = Object.assign({}, item);
            delete downloadItem.id;
            downloadItems.push(downloadItem);
        }
        downloadReceipt.items = downloadItems;
        downloadReceipts.push(downloadReceipt);
    }
    return downloadReceipts;
}

export function validateJson(json) {
    const valid = validate(json);
    if (!valid) {
        console.log("Error while validating uploaded .json file:");
        console.log(validate.errors);
    }
    return valid;
}