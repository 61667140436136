import React from 'react';
import PropTypes from "prop-types";
import ProductCard from "./ProductCard";
import {getItemFeedbackById} from "../../data/FeedbackUtil";


class ProductCardList extends React.Component {
    renderCards() {
        let cards = [];
        for (let i = 0; i < this.props.items.length; i++) {
            cards.push(
                <ProductCard key={this.props.items[i].id} addProductMode={this.props.addProductMode} item={this.props.items[i]}
                             itemFeedback={getItemFeedbackById(this.props.itemFeedbacks, this.props.items[i].id)}
                             onChange={(itemId, fieldName, newValue) => this.props.onChange(itemId, fieldName, newValue)}
                             onAdd={() => this.props.onAdd(i)} onDelete={(itemId) => this.props.onDelete(itemId)}/>
            );
        }
        return cards;
    }

    render() {
        return (
            <>
                {this.renderCards()}
            </>
        );
    }
}

ProductCardList.propTypes = {
    addProductMode: PropTypes.bool.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        number: PropTypes.string.isRequired,
        amount: PropTypes.string.isRequired,
        unit_price: PropTypes.string.isRequired,
        weight: PropTypes.string.isRequired,
        si_unit: PropTypes.string.isRequired,
        price_per_si_unit: PropTypes.string.isRequired,
        total_price: PropTypes.string.isRequired,
        tax_category: PropTypes.string.isRequired,
        category: PropTypes.string.isRequired
    }).isRequired).isRequired,
    itemFeedbacks: PropTypes.arrayOf(PropTypes.shape({
        itemId: PropTypes.number.isRequired,
        feedbacks: PropTypes.arrayOf(PropTypes.shape({
            fieldName: PropTypes.string.isRequired,
            severity: PropTypes.oneOf(["info", "warning", "error"]).isRequired,
            message: PropTypes.string.isRequired
        }).isRequired)
    })),
    onChange: PropTypes.func.isRequired,
    onAdd: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired
};

export default ProductCardList;