import React from 'react';

import {withTranslation} from "react-i18next";
import {Button} from "primereact/button";
import {Menu} from "primereact/menu";
import {PrimeIcons} from "primereact/api";

class DebugMenu extends React.Component {
    constructor(props) {
        super(props);

        this.menu = null;

    }

    getMenuItems() {
        return [
            {
                label: "Debug options",
                items: [
                    {
                        label: "Show binarized image",
                        icon: PrimeIcons.FILE,
                        url: this.props.debug !== null ? this.props.debug.binarizedImageUrl : undefined,
                        target: "_blank",
                        disabled: this.props.debug === null || this.props.debug.binarizedImageUrl === null // nope PhpStorm, this cannot be simplified
                    }, {
                        label: "Show recognized text areas",
                        icon: PrimeIcons.TH_LARGE,
                        url: this.props.debug !== null ? this.props.debug.recognizedTextAreasUrl : undefined,
                        target: "_blank",
                        disabled: this.props.debug === null || this.props.debug.recognizedTextAreasUrl === null // nope PhpStorm, this cannot be simplified
                    }, {
                        label: "Show recognized line areas",
                        icon: PrimeIcons.ALIGN_JUSTIFY,
                        url: this.props.debug !== null ? this.props.debug.recognizedLineAreasUrl : undefined,
                        target: "_blank",
                        disabled: this.props.debug === null || this.props.debug.recognizedLineAreasUrl === null // nope PhpStorm, this cannot be simplified
                    }, {
                        label: "Show plain text",
                        icon: PrimeIcons.ALIGN_LEFT,
                        url: this.props.debug !== null ? this.props.debug.plainTextUrl : undefined,
                        target: "_blank",
                        disabled: this.props.debug === null || this.props.debug.plainTextUrl === null // nope PhpStorm, this cannot be simplified
                    }, {
                        label: "Show line types",
                        icon: PrimeIcons.TAGS,
                        url: this.props.debug !== null ? this.props.debug.lineTypesUrl : undefined,
                        target: "_blank",
                        disabled: this.props.debug === null || this.props.debug.lineTypesUrl === null // nope PhpStorm, this cannot be simplified
                    }
                ]
            }
        ];
    }

    render() {
        return (
            <>
                <Button icon="pi pi-wrench" label="Debug"
                        className="p-button-secondary p-button-rounded my-3 ml-0 mr-3 shadow-4 pointer-events-auto"
                        onClick={(e) => this.menu.toggle(e)}
                        aria-controls="popup_menu" aria-haspopup
                        disabled={this.props.debug === null}
                >
                    <span className="p-button-icon p-c p-button-icon-right pi pi-chevron-up"/>
                </Button>
                <Menu model={this.getMenuItems()} popup ref={(el) => this.menu = el} id="popup_menu"/>
            </>
        );
    }
}

export default withTranslation()(DebugMenu);