// ReceiptsInProcess

export class ReceiptProcessStatus {
    static CREATE_PROJECT = new ReceiptProcessStatus("CREATE_PROJECT");
    static WAIT_FOR_RESULT = new ReceiptProcessStatus("WAIT_FOR_RESULT");

    constructor(name) {
        this.name = name;
    }
}

/**
 * Creates a copy of the receiptsInProcess list, adds the newReceiptInProcess to the copied list and returns the
 * copied list.
 *
 * @param {ReceiptInProcess[]} receiptsInProcess The old receiptsInProcess list.
 * @param {ReceiptInProcess} newReceiptInProcess The ReceiptInProcess object that should be added to the list.
 * @returns {ReceiptInProcess[]} A copy of the old receiptsInProcess list that contains the newReceiptInProcess.
 * @throws {Error} If there is already a ReceiptInProcess object in the list that has the same receiptId of the newReceiptInProcess.
 */
export function addReceiptInProcess(receiptsInProcess, newReceiptInProcess) {
    let newReceiptsInProcess = receiptsInProcess.slice();
    let exists = newReceiptsInProcess.some((rip) => rip.receiptId === newReceiptInProcess.receiptId);
    if (exists) {
        throw new Error("There is already a ReceiptInProcess with the id " + newReceiptInProcess.receiptId + " in the list.");
    }
    newReceiptsInProcess.push(newReceiptInProcess);
    return newReceiptsInProcess;
}

/**
 * Creates a copy of the receiptsInProcess list. Then it looks for the first ReceiptInProcess object in the list
 * that has the same receiptId as the changedReceiptInProcess. If it finds an object with the same id, it is replaced
 * with the changedReceiptInProcess. Otherwise, an error is thrown.
 *
 * @param {ReceiptInProcess[]} receiptsInProcess The old receiptsInProcess list.
 * @param {ReceiptInProcess} changedReceiptInProcess The ReceiptInProcess object that should replace the ReceiptInProcess object in the list that has the same receiptId.
 * @returns {ReceiptInProcess[]} A copy of the old receiptsInProcess list in which an element is replaced by the changedReceiptInProcess.
 * @throws {Error} If there is no ReceiptInProcess object in the list that has the receiptId of the changedReceiptInProcess.
 */
export function changeReceiptInProcess(receiptsInProcess, changedReceiptInProcess) {
    let newReceiptsInProcess = receiptsInProcess.slice();
    let index = newReceiptsInProcess.findIndex((rip) => rip.receiptId === changedReceiptInProcess.receiptId);
    if (index === -1) {
        throw new Error("There is no ReceiptInProcess with the id " + changedReceiptInProcess.receiptId + " in the receiptsInProcess list.");
    }
    newReceiptsInProcess.splice(index, 1, changedReceiptInProcess);
    return newReceiptsInProcess;
}

/**
 * Creates a copy of the receiptsInProcess list. Then it looks for the first ReceiptInProcess object in the list
 * that has the given receiptId. If it finds an object with this id, it will be deleted from the new list.
 * Otherwise, an error is thrown.
 *
 * @param {ReceiptInProcess[]} receiptsInProcess The old receiptsInProcess list.
 * @param {number} receiptId The receiptId of the ReceiptInProcess object that should not be contained in the returned list.
 * @returns {ReceiptInProcess[]} A copy of the old receiptsInProcess list in which the element with the given id is removed.
 * @throws {Error} If there is no ReceiptInProcess object in the list that has the given receiptId.
 */
export function removeReceiptInProcess(receiptsInProcess, receiptId) {
    let newReceiptsInProcess = receiptsInProcess.slice();
    let index = newReceiptsInProcess.findIndex((rip) => rip.receiptId === receiptId);
    if (index === -1) {
        throw new Error("There is no ReceiptInProcess with the id " + receiptId + " in the receiptsInProcess list.");
    }
    newReceiptsInProcess.splice(index, 1);
    return newReceiptsInProcess;
}

/**
 * Gets the ReceiptInProcess object for a given receiptId.
 *
 * @param {ReceiptInProcess[]} receiptsInProcess The receiptsInProcess list.
 * @param {number} receiptId The receiptId that the returned ReceiptInProcess object should have.
 * @returns {ReceiptInProcess|null} A ReceiptInProcess object that has the same receiptId as given. If there is no such object in the list, null is returned.
 */
export function getReceiptInProcess(receiptsInProcess, receiptId) {
    let receiptInProcess = receiptsInProcess.find((rip) => rip.receiptId === receiptId);
    return receiptInProcess !== undefined ? receiptInProcess : null;
}

// InputDataInProcess

/**
 * Creates a copy of the inputDataInProcess list, adds the newId to the copy and then returns it.
 * @param {number[]} inputDataInProcess The old inputDataInProcess list.
 * @param {number} newId The id that represents the new input data process.
 * @returns {number[]} A copy of the inputDataInProcess list that also contains the new id.
 * @throws {Error} If the id is already in the inputDataInProcess list.
 */
export function addInputDataInProcess(inputDataInProcess, newId) {
    let newInputDataInProcess = inputDataInProcess.slice();
    let exists = newInputDataInProcess.indexOf(newId) !== -1;
    if (exists) {
        throw new Error("There is already a InputDataInProcess with the id " + newId + " in the list.");
    }
    newInputDataInProcess.push(newId);
    return newInputDataInProcess;
}

/**
 * Creates a copy of the inputDataInProcess list, removes the id of the copy and then returns it.
 * @param {number[]} inputDataInProcess The old inputDataInProcess list.
 * @param {number} id The id that represents the input data process that should be removed.
 * @returns {number[]} A copy of the inputDataInProcess list that does not contain the id.
 * @throws {Error} If the id is not in the inputDataInProcess list.
 */
export function removeInputDataInProcess(inputDataInProcess, id) {
    let newInputDataInProcess = inputDataInProcess.slice();
    let index = newInputDataInProcess.indexOf(id);
    if (index === -1) {
        throw new Error("There is no InputDataInProcess with the id " + id + " in the list.");
    }
    newInputDataInProcess.splice(index, 1);
    return newInputDataInProcess;
}