import React from 'react';
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import PropTypes from "prop-types";
import {getFieldFeedbackByName, getFieldFeedbacks, getItemFeedbackById} from "../../data/FeedbackUtil";
import FeedbackInputText from "../input_elements/FeedbackInputText";

import {withTranslation} from "react-i18next";
import {ConfirmDialog, confirmDialog} from "primereact/confirmdialog";

class ProductTable extends React.Component {
    constructor(props) {
        super(props);

        this.localId = -1;
    }

    getIcon(severity) {
        if (severity === "info") {
            return "pi-info-circle";
        } else if (severity === "warning") {
            return "pi-exclamation-triangle";
        } else if (severity === "error") {
            return "pi-exclamation-circle";
        }
        throw new Error("Invalid severity value \"" + severity + "\".");
    }

    getColor(severity) {
        if (severity === "info") {
            return "cyan-500";
        } else if (severity === "warning") {
            return "orange-500";
        } else if (severity === "error") {
            return "red-500";
        }
    }

    onCellEditComplete(e) {
        //e.rowData[e.field] = e.newValue;
        this.props.onChange(e.rowData.id, e.field, e.newValue);
    }

    textCellEditor(options, width, field, label) {
        let itemId = options.rowData.id;
        let itemFeedback = getItemFeedbackById(this.props.itemFeedbacks, itemId);
        let fieldFeedback = getFieldFeedbackByName(getFieldFeedbacks(itemFeedback), field);
        return (
            <div className="grid formgrid p-fluid grid-nogutter">
                <FeedbackInputText columnType={"col-12"} name={"field"} label={label} value={options.value}
                                   onChange={(newValue) => options.editorCallback(newValue)}
                                   feedback={fieldFeedback}/>
            </div>
        );
    }

    cellTemplate(rowData, field) {
        let itemId = rowData.id;
        let itemFeedback = getItemFeedbackById(this.props.itemFeedbacks, itemId);
        let fieldFeedback = getFieldFeedbackByName(getFieldFeedbacks(itemFeedback), field);
        if (fieldFeedback === null) {
            return (
                <div className="flex align-items-center justify-content-start">
                    <span>{rowData[field]}</span>
                </div>
            );
        } else {
            return (
                <div className="flex align-items-center justify-content-start">
                    <i className={"pi " + this.getIcon(fieldFeedback.severity) + " text-" + this.getColor(fieldFeedback.severity)}/>
                    <span className={"text-" + this.getColor(fieldFeedback.severity)}>{rowData[field]}</span>
                </div>
            );
        }
    }

    actionTemplate(rowData) {
        return (
            <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-outlined"
                    aria-label="Delete"
                    onClick={() => this.confirmDelete(rowData.id)}
            />
        );
    }

    confirmDelete(id) {
        const t = this.props.t;
        confirmDialog({
            message: t("receipts.deleteProductMessage"),
            header: t("common.confirmDelete"),
            icon: "pi pi-trash",
            acceptLabel: t("common.delete"),
            rejectLabel: t("common.cancel"),
            acceptClassName: "p-button-danger",
            accept: () => this.props.onDelete(id),
            reject: () => {}
        });
    }

    render() {
        const t = this.props.t;
        this.localId = -1;
        return (
            <>
                <DataTable scrollable scrollHeight={"calc(100vh - 20rem)"} scrollDirection="both" value={this.props.items}
                           header={this.props.addProductMode ? t("receipts.clickLineToInsertBefore") : t("receipts.products")}
                           emptyMessage={t("receipts.noProducts")} dataKey="id"
                           style={{height: "calc(100vh - 17rem)"}} showGridlines
                           onRowClick={this.props.addProductMode ? (e) => this.props.onAdd(e.index) : null}
                >
                    <Column header="" body={(rowData) => this.actionTemplate(rowData)} style={{width: "5rem"}}/>
                    <Column field="number" header={t("receipts.number")} body={(rowData) => this.cellTemplate(rowData, "number")}
                            editor={this.props.addProductMode ? null : options => this.textCellEditor(options, 1, "number", t("receipts.number"))}
                            onCellEditComplete={e => this.onCellEditComplete(e)} style={{width: "6rem"}}/>
                    <Column field="name" header={t("receipts.productName")} body={(rowData) => this.cellTemplate(rowData, "name")}
                            editor={this.props.addProductMode ? null : options => this.textCellEditor(options, 19, "name", t("receipts.productName"))}
                            onCellEditComplete={e => this.onCellEditComplete(e)} style={{width: "15rem"}}/>
                    <Column field="amount" header={t("receipts.amount")} body={(rowData) => this.cellTemplate(rowData, "amount")}
                            editor={this.props.addProductMode ? null : options => this.textCellEditor(options, 1, "amount", t("receipts.amount"))}
                            onCellEditComplete={e => this.onCellEditComplete(e)} style={{width: "6rem"}}/>
                    <Column field="unit_price" header={t("receipts.unitPrice")}
                            body={(rowData) => this.cellTemplate(rowData, "unit_price")}
                            editor={this.props.addProductMode ? null : options => this.textCellEditor(options, 1, "unit_price", t("receipts.unitPrice"))}
                            onCellEditComplete={e => this.onCellEditComplete(e)} style={{width: "6rem"}}/>
                    <Column field="weight" header={t("receipts.weight")} body={(rowData) => this.cellTemplate(rowData, "weight")}
                            editor={this.props.addProductMode ? null : options => this.textCellEditor(options, 8, "weight", t("receipts.weight"))}
                            onCellEditComplete={e => this.onCellEditComplete(e)} style={{width: "10rem"}}/>
                    <Column field="si_unit" header={t("receipts.siUnit")} body={(rowData) => this.cellTemplate(rowData, "si_unit")}
                            editor={this.props.addProductMode ? null : options => this.textCellEditor(options, 1, "si_unit", t("receipts.siUnit"))}
                            onCellEditComplete={e => this.onCellEditComplete(e)} style={{width: "6rem"}}/>
                    <Column field="price_per_si_unit" header={t("receipts.pricePerSiUnit")}
                            body={(rowData) => this.cellTemplate(rowData, "price_per_si_unit")}
                            editor={this.props.addProductMode ? null : options => this.textCellEditor(options, 1, "price_per_si_unit", t("receipts.pricePerSiUnit"))}
                            onCellEditComplete={e => this.onCellEditComplete(e)} style={{width: "6rem"}}/>
                    <Column field="total_price" header={t("receipts.totalPrice")}
                            body={(rowData) => this.cellTemplate(rowData, "total_price")}
                            editor={this.props.addProductMode ? null : options => this.textCellEditor(options, 1, "total_price", t("receipts.totalPrice"))}
                            onCellEditComplete={e => this.onCellEditComplete(e)} style={{width: "6rem"}}/>
                    <Column field="tax_category" header={t("receipts.tax")}
                            body={(rowData) => this.cellTemplate(rowData, "tax_categories")}
                            editor={this.props.addProductMode ? null : options => this.textCellEditor(options, 1, "tax_categories", t("receipts.tax"))}
                            onCellEditComplete={e => this.onCellEditComplete(e)} style={{width: "6rem"}}/>
                    <Column field="category" header={t("receipts.category")} style={{width: "20rem"}}
                            body={(rowData) => this.cellTemplate(rowData, "category")}/>
                </DataTable>
                <ConfirmDialog/>
            </>
        );
    }
}

ProductTable.propTypes = {
    addProductMode: PropTypes.bool.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        number: PropTypes.string.isRequired,
        amount: PropTypes.string.isRequired,
        unit_price: PropTypes.string.isRequired,
        weight: PropTypes.string.isRequired,
        si_unit: PropTypes.string.isRequired,
        price_per_si_unit: PropTypes.string.isRequired,
        total_price: PropTypes.string.isRequired,
        tax_category: PropTypes.string.isRequired,
        category: PropTypes.string.isRequired
    }).isRequired).isRequired,
    itemFeedbacks: PropTypes.arrayOf(PropTypes.shape({
        itemId: PropTypes.number.isRequired,
        feedbacks: PropTypes.arrayOf(PropTypes.shape({
            fieldName: PropTypes.string.isRequired,
            severity: PropTypes.oneOf(["info", "warning", "error"]).isRequired,
            message: PropTypes.string.isRequired
        }).isRequired)
    })),
    onChange: PropTypes.func.isRequired,
    onAdd: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired
};

export default withTranslation()(ProductTable);