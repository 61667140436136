import React from 'react';
import {Button} from "primereact/button";
import {SelectButton} from "primereact/selectbutton";
import {withTranslation} from "react-i18next";
import PageType from "./PageType";

class Header extends React.Component {
    renderHeader() {
        const t = this.props.t;
        switch (this.props.openedPage.type) {
            case PageType.START:
                return t("start.title");
            case PageType.RECEIPT:
                return t("receipts.title");
            case PageType.STATISTIC:
                return t("statistics.title");
            case PageType.DATA_ADMINISTRATION:
                return t("dataAdministration.title");
        }
    }

    render() {
        const i18n = this.props.i18n;
        return (
            <>
            <div className="fixed block shadow-4 p-2 z-5 min-w-full bg-white">
                <div className="flex justify-content-between align-items-center gap-2">
                    <div className="flex justify-content-start align-items-center gap-2">
                        <Button icon="pi pi-bars" className="p-button-rounded p-button-text" aria-label="Menu" onClick={this.props.onMenuOpen}/>
                        <h3>{this.renderHeader()}</h3>
                    </div>
                    <div>
                        <SelectButton value={i18n.resolvedLanguage} options={this.props.languages} onChange={(e) => i18n.changeLanguage(e.value)}/>
                    </div>
                </div>

            </div>
            <div className="p-3"/><div className="p-4"/>
            </>
        );
    }
}

export default withTranslation()(Header);