import React from 'react';

import {withTranslation} from "react-i18next";
import {Panel} from "primereact/panel";
import {Divider} from "primereact/divider";
import DatabaseUpload from "../start_page/DatabaseUpload";
import {Button} from "primereact/button";
import PropTypes from "prop-types";
import LegalPageBottom from "../LegalPageBottom";

class DataAdministrationPage extends React.Component {
    render() {
        const t = this.props.t;
        return (
            <>
                <div className="flex flex-column align-items-stretch justify-content-center gap-2 my-2">
                    <Panel header={this.renderExportHeader()} className="mx-2 md:mx-4 lg:mx-8">
                        <div className="grid grid-nogutter">
                            <div className="col-12 lg:col-5">
                                {t("dataAdministration.exportDescription")}
                            </div>
                            <div className="hidden lg:block col-2">
                                <Divider layout="vertical"/>
                            </div>
                            <div className="block lg:hidden col-12">
                                <Divider layout="horizontal"/>
                            </div>
                            <div className="col-12 lg:col-5">
                                <div className="flex flex-column align-items-center justify-content-center gap-2 h-full">
                                    <Button icon="pi pi-download" label={t("dataAdministration.exportReceipts")} onClick={() => this.props.onExport()}/>
                                </div>
                            </div>
                        </div>
                    </Panel>
                    <Panel header={this.renderImportHeader()} className="mx-2 md:mx-4 lg:mx-8">
                        <div className="grid grid-nogutter">
                            <div className="col-12 lg:col-5">
                                {t("dataAdministration.importDescription")}
                            </div>
                            <div className="hidden lg:block col-2">
                                <Divider layout="vertical"/>
                            </div>
                            <div className="block lg:hidden col-12">
                                <Divider layout="horizontal"/>
                            </div>
                            <div className="col-12 lg:col-5">
                                <div className="flex flex-column align-items-center justify-content-center gap-2 h-full">
                                    <DatabaseUpload databaseFile={this.props.importFile}
                                                    onDatabaseChange={(database) => this.props.onImportFileChange(database)}
                                                    validDatabaseFile={this.props.validImport}
                                                    loadDatabaseFile={this.props.loadImport}
                                                    usedDatabaseFile={this.props.usedImport}
                                    />
                                    <Button icon="pi pi-upload" label={t("dataAdministration.importReceipts")} disabled={!this.isImportReady()}
                                            onClick={() => this.props.onImport()}
                                    />
                                </div>
                            </div>
                        </div>
                    </Panel>
                </div>
                <LegalPageBottom bottomOffset="1rem"/>
            </>
        );
    }

    renderImportHeader() {
        const t = this.props.t;
        return (
            <div className="flex justify-content-start align-items-center gap-2">
                <i className="pi pi-upload"/>
                {t("dataAdministration.importData")}
            </div>
        );
    }

    renderExportHeader() {
        const t = this.props.t;
        return (
            <div className="flex justify-content-start align-items-center gap-2">
                <i className="pi pi-download"/>
                {t("dataAdministration.exportData")}
            </div>
        );
    }

    isImportReady() {
        return !this.props.loadImport && this.props.validImport;
    }
}

DataAdministrationPage.propTypes = {
    onExport: PropTypes.func.isRequired,
    onImport: PropTypes.func.isRequired,
    usedImport: PropTypes.bool.isRequired,
    loadImport: PropTypes.bool.isRequired,
    validImport: PropTypes.bool.isRequired,
    importFile: PropTypes.object,
    onImportFileChange: PropTypes.func.isRequired
}

export default withTranslation()(DataAdministrationPage);