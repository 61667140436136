import React from 'react';
import {Button} from "primereact/button";
import {Image as PrimeImage} from "primereact/image";

import {withTranslation} from "react-i18next";

class ImageUpload extends React.Component {
    // properties:
    // image file
    // image url



    constructor(props) {
        super(props);

        this.imageHeight = 250;

        this.input = null;
        this.image = null;

        this.state = {
            width: 50,
            height: 50
        }
    }

    calculateSize() {
        let img = new Image();
        let self = this;
        img.onload = function() {
            let width = Math.round(this.width * (self.imageHeight / this.height));
            self.setState({
                width: width,
                height: self.imageHeight
            });
        };
        img.src = this.props.imageUrl;
    }

    componentDidMount() {
        if (this.props.imageUrl !== null) {
            this.calculateSize();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.imageUrl !== this.props.imageUrl && this.props.imageUrl !== null) {
            this.calculateSize();
        }
    }

    renderImage() {
        const t = this.props.t;
        if (this.props.imageUrl !== null) {
            return (
                <PrimeImage ref={(el) => this.image = el} src={this.props.imageUrl} alt={t("start.imageAlt")} width={this.state.width} height={this.state.height} onClick={() => this.input.click()} className="cursor-pointer"/>
            );
        } else {
            return (
                <div className="flex flex-column align-items-center justify-content-center surface-500 p-6 border-round cursor-pointer" onClick={() => this.input.click()}>
                    <i className="pi pi-camera text-white" style={{'fontSize': '4em'}}/>
                </div>
            );
        }
    }

    renderButton() {
        const t = this.props.t;
        if (this.props.imageUrl === null) {
            return (
                <Button label={t("start.takePhoto")} icon="pi pi-camera" onClick={() => this.input.click()}/>
            );
        } else {
            return (
                <span className="p-buttonset">
                    <Button label={t("start.replacePhoto")} icon="pi pi-camera" onClick={() => this.input.click()}/>
                    <Button label={t("start.removePhoto")} icon="pi pi-trash" onClick={() => this.removeImage()}/>
                </span>
            );
        }
    }

    render() {
        return (
            <>
                {this.renderImage()}
                {this.renderButton()}
                <input className="hidden" type="file" accept="image/*" ref={(el) => this.input = el} onChange={(e) => this.onImageSelect()}/>
            </>
        );
    }

    removeImage() {
        this.input.value = '';
        this.onImageSelect();
    }

    onImageSelect() {
        if (this.input.files.length === 1) {
            this.props.onImageChange(this.input.files[0]);
        } else {
            this.props.onImageChange(null);
        }
    }
}

export default withTranslation()(ImageUpload);