import React from 'react';
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import PropTypes from "prop-types";
import FeedbackInputText from "../input_elements/FeedbackInputText";
import {getFieldFeedbackByName} from "../../data/FeedbackUtil";

import {withTranslation} from "react-i18next";
import {confirmDialog, ConfirmDialog} from "primereact/confirmdialog";


class ProductEditDialog extends React.Component {
    getFields() {
        const t = this.props.t;
        return [
            {
                name: "number",
                label: t("receipts.number"),
                columnType: "col-12"
            },
            {
                name: "name",
                label: t("receipts.productName"),
                columnType: "col-12"
            },
            {
                name: "amount",
                label: t("receipts.amount"),
                columnType: "col-6"
            },
            {
                name: "unit_price",
                label: t("receipts.unitPrice"),
                columnType: "col-6"
            },
            {
                name: "weight",
                label: t("receipts.weight"),
                columnType: "col-4"
            },
            {
                name: "si_unit",
                label: t("receipts.siUnit"),
                columnType: "col-4"
            },
            {
                name: "price_per_si_unit",
                label: t("receipts.pricePerSiUnit"),
                columnType: "col-4"
            },
            {
                name: "total_price",
                label: t("receipts.totalPrice"),
                columnType: "col-8"
            },
            {
                name: "tax_category",
                label: t("receipts.tax"),
                columnType: "col-4"
            },
            {
                name: "category",
                label: t("receipts.category"),
                columnType: "col-12"
            }
        ];
    }

    confirmDelete() {
        const t = this.props.t;
        confirmDialog({
            message: t("receipts.deleteProductMessage"),
            header: t("common.confirmDelete"),
            icon: "pi pi-trash",
            acceptLabel: t("common.delete"),
            rejectLabel: t("common.cancel"),
            acceptClassName: "p-button-danger",
            accept: () => this.props.onDelete(),
            reject: () => {}
        });
    }

    renderInputs() {
        return this.getFields().map(field => {
            return <FeedbackInputText key={field.name} columnType={field.columnType} name={field.name}
                                      label={field.label} value={this.props.item[field.name]}
                                      onChange={(newValue) => this.props.onChange(field.name, newValue)}
                                      feedback={getFieldFeedbackByName(this.props.fieldFeedbacks, field.name)}/>;
        });
    }

    renderHeader() {
        const t = this.props.t;
        return (
            <div className="flex justify-content-between align-items-center">
                <div className="flex justify-content-start align-items-center gap-2">
                    <Button icon="pi pi-arrow-left" className="p-button-rounded p-button-outlined" onClick={() => this.props.onHide()}/>
                    <div>
                        {t("receipts.editProduct")}
                    </div>
                </div>
                <div className="flex justify-content-end align-items-center">
                    <Button icon="pi pi-trash" className="p-button-danger p-button-rounded p-button-outlined" onClick={() => this.confirmDelete()}/>
                </div>
            </div>
        );
    }

    render() {
        const t = this.props.t;
        return (
            <Dialog visible={this.props.visible} onHide={() => this.props.onHide()} maximized={true} blockScroll={false} closable={false}
                    header={this.renderHeader()}>
                <div className="grid formgrid p-fluid mt-2">
                    {this.renderInputs()}
                </div>
                <ConfirmDialog/>
            </Dialog>
        );
    }
}

ProductEditDialog.propTypes = {
    visible: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    item: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        number: PropTypes.string.isRequired,
        amount: PropTypes.string.isRequired,
        unit_price: PropTypes.string.isRequired,
        weight: PropTypes.string.isRequired,
        si_unit: PropTypes.string.isRequired,
        price_per_si_unit: PropTypes.string.isRequired,
        total_price: PropTypes.string.isRequired,
        tax_category: PropTypes.string.isRequired,
        category: PropTypes.string.isRequired
    }).isRequired,
    fieldFeedbacks: PropTypes.arrayOf(PropTypes.shape({
        fieldName: PropTypes.string.isRequired,
        severity: PropTypes.oneOf(["info", "warning", "error"]).isRequired,
        message: PropTypes.string.isRequired
    }).isRequired),
    onChange: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired
};

export default withTranslation()(ProductEditDialog);