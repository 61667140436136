let nextId = 0;

/**
 * Returns a unique number that has never returned since this script is running.
 * Can be used everytime a unique identifier is needed.
 * @returns {number} A unique number.
 */
export function claimNextId() {
    let id = nextId;
    nextId = nextId + 1;
    return id;
}